//@import "../lib/bootstrap-5.0.0-alpha1-dist/css/bootstrap-grid.min.css";
// @import "../lib/bootstrap-5.0.0-alpha1-dist/css/bootstrap.min.css";
// @import "../lib/bootstrap-5.0.0-alpha1-dist/css/bootstrap-utilities.min.css";
// @import "../lib/bootstrap-5.0.0-alpha1-dist/css/bootstrap-reboot.min.css";
@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import '../fonts/promt/stylesheet.css';
@import './magnific-popup.css';
// @import '../fonts/flaticon/font/flaticon.css';
// @import '../fonts/linearicons/style.css';
// @import './jquery.mCustomScrollbar.css';
// @import './dropzone.css';
@import './slick.css';


// @import './lightbox.min.css';
// @import './jnoty.css';

//@import './sidebar.css';
//@import './style.css';
//@import './skins/red.css';

@import './ie10-viewport-bug-workaround.css';
//@import './box-tool.scss';


/*
@import '~mdb-ui-kit/css/mdb.min.css';

@import '~mdb-ui-kit/src/scss/mdb.free.scss';
@import '../fonts/Montserrat/stylesheet.css';

@import '../templates/ts/_custom-style.scss';
@import '../templates/ts/jquery.fancybox.min.css';
@import '../templates/ts/select2.min.css';
*/
