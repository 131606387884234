
.text-theme {
    color: #F26623 !important;
}
.bg-theme {

    background-color: #F26623;
}
.bg-footer {
    background-color: #F26623 !important;
}

.border-bottom-theme
{
    
    border-bottom: #F26623 3px solid;
}
.btn-check:focus + .btn, .btn:focus{
    color:#F26623;
    background-color: #fff;
}
.btn-link{
    color:#F26623;
    
}
.btn-primary span{
    color:#fff;
}
.carousel-item {

    display:unset !important;
}

.carousel-fade .carousel-item{
    opacity: 1 !important;
}

.bg-announce {
    background-color: rgb(251, 209, 188);
}
.banner-50vh {
    height:50vh;
}
.border-footer{
    border-color: rgb(172, 117, 84) !important;
}

.btn-deep-blue {
    background-color: rgb(27, 40, 92) !important;
    z-index:100;
}

.bg-review {
background-color: rgb(255, 226, 210);
}

.text-brown {
    color:rgb(147,83, 58);
}

.mt-35m{
    margin-top:35px;
}
.product-transform {

    transform: translate(10%, -10%);
}



   /* Portrait tablet to landscape and desktop */
   @media (min-width: 768px) and (max-width: 979px) { 
   /* your code */
   .product-transform {

    transform: unset !important;
}


    }
   
   /* Landscape phone to portrait tablet */
   @media (max-width: 767px) { 
   /* your code */  .product-transform {

    transform: unset !important;
}
.px-5 {
    padding-right: unset !important;
    padding-left: unset !important;
}
.row.pt5 {
    padding-top:0px !important;
}
    }
   
   /* Landscape phones and down */
   @media (max-width: 480px) { 
   /* your code */  .product-transform {

    transform: unset !important;
}
.px-5 {
    padding-right: unset !important;
    padding-left: unset !important;
}

.row.pt5 {
    padding-top:0px !important;
}
    }


    
.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width:100%;
    height:100%;
    background-color: #F26623;
    
  }
  
  .item:hover .image {
    opacity: 0.5;
    
  }
  
  .item:hover .middle {
    opacity: 0.5;
  }
  
  .text {
    
    color: white;
    
  }


  .card-img-left {
    width: auto;
    height: 100%;
    border-radius: calc(0.25rem - 1px) 0 0 calc(0.25rem - 1px);
}
.cke{
    margin-bottom:1rem;
}