/*------------------------------------------------------------------
[Main Stylesheet]

Project:    Autocar
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    1. Top Header / .top-header
    2. Main Header / .main-header
    3. Home Page Banner (Main Home) / .banner
    4. Banner Search Box / .search-area
    5. Featured Car / .featured-car
    6. Single Page Banner / .sub-banner
    7. Car List View / .car-box-2
    8. Car Grid View / .car-box
    9. Single Car Details / .car-details-page
    10. Listing Page Sidebar /.sidebar
    11. Testimonials / .testimonials
    12. Pricing Table /.pricing-container
    13. Agent Contact Form /.agent-widget
    14. Car Comments /.car-comments
    15. Specifications /.specifications
    16. Application Counter / .counters
    17. Blog /.blog-body
    18. Contact Form /.contact-form
    19. Signup/Login Wrapper /.form-content-box
    20. Pagination /.pagination
    21. Main Footer / .main-footer
    22. Sub Footer / .sub-footer
-------------------------------------------------------------------*/
/** Global classes**/

@charset 'UTF-8';


/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: #F26623;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


.zandgar__wizard {
    display: block;
    height: 100%;
    position: relative;
  }
  
  .zandgar__wizard .zandgar__step {
    display: none;
  }
  .zandgar__wizard .zandgar__step.zandgar__step__active {
    display: block;
  }
  
  .col-img {
    /* https://unsplash.com/photos/yWwob8kwOCk */
    background: scroll center url('https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80');
    background-size: cover;
    min-height: 100vh;
  }

  #steps-native li a.active {
    color: #fff;
    background-color: #F26623;
  }
  
.bg-blue {
    background-color: rgb(17, 40, 124);
}
ul.ul-partner li::before  {
  
        content: "\2022";
        color: #F26623;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
      
}
.multiple-items .slider-prev {
 
    position: absolute;
    top: 40%;
    display: block;
    width: 20px;
    height: 20px;
    left: -15px;
    padding: -267px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
  
}

.multiple-items .slider-next {

    position: absolute;
    top: 40%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    right: -25px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
  
    border: none;
    outline: none;
  
}
html, body {
    height: 100%;
    font-family: 'Prompt', sans-serif;
    background: #f9f9f9;
}

a {
    cursor: pointer;
    color: #212121;
    text-decoration: none;
}

a:hover {
    color: #5a5959;
    text-decoration: none;
}

p {
    font-size: 16px;
    line-height: 25px;
    color: #5a5959;
}

strong {
    color: #5a5959;
}

span {
    color: #5a5959;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Prompt', sans-serif;
    color: #212121;
}

ul {
    margin: 0;
    /* padding: 0; */
    /* list-style: none; */
    color: #5a5959;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}

/** Social media color **/
.facebook:hover {
    color: #3b589e !important;
}

.twitter:hover {
    color: #56d7fe !important;
}

.google:hover {
    color: #dc4e41 !important;
}

.rss:hover {
    color: #e78733 !important;
}

.linkedin:hover {
    color: #1c82ca !important;
}

/** Social media bg**/
.facebook-bg {
    background: #4867aa;
    color: #fff;
}

.twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.google-bg {
    background: #db4437;
    color: #fff;
}

.linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.rss-bg{
    background: #e78733;
    color: #fff;
}

.pinterest-bg {
    background: #bd081c;
    color: #fff;
}

.facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

.google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.rss-bg:hover {
    background: #e78701;
    color: #fff;
}

.pinterest-bg:hover {
    background: #a30618;
    color: #fff;
}

.delay-02s {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s
}

.delay-03s {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s
}

.delay-04s {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s
}

.delay-05s {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s
}

.delay-06s {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s
}

.delay-07s {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s
}

.delay-08s {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s
}

.delay-09s {
    animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s
}

.delay-1s {
    animation-delay: 1s;
    -webkit-animation-delay: 1s
}

.delay-12s {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s
}

.delay-15s {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s
}

.delay-17s {
    animation-delay: 1.7s;
    -webkit-animation-delay: 1.7s
}

.delay-20s {
    animation-delay: 2.0s;
    -webkit-animation-delay: 2.0s
}

/** Btn **/
.btn-sm {
    cursor: pointer;
    padding: 7px 12px;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    transition: .5s;
    border-radius: 2px;
    border: none;
    color: #fff;
}

.btn-sm:hover {
    color: #fff;
    text-decoration: none;
}

.btn-md {
    cursor: pointer;
    padding: 13px 26px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.btn-md:hover {
    color: #fff;
}

button:focus {
    outline: 0 auto -webkit-focus-ring-color;
}

.btn-block i{
    color: #fff;
}

.bomd {
    padding: 13px 26px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    transition: .5s;
    border-radius: 2px;
}

.btn {
    border-radius: 0px;
}


.btn-sm {
    padding: 8px 30px 6px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.btn-md {
    cursor: pointer;
    padding: 15.5px 30px 13.5px 30px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 3px;
}

.btn:focus, .btn:active, .btn:focus {
    box-shadow: none !important;
    outline: none;
}

.btn-theme {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}

.btn-theme:hover {
    color: #FFF;
}

.btn-white-lg-outline {
    color: #FFF;
    background: transparent;
    border: solid 2px #FFF;
    cursor: pointer;
    padding: 11px 35px 10px 35px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.btn-white-lg-outline:hover {
    color: #FFF;
}

.btn-round {
    border-radius: 30px;
}

.btns-black {
    background: #292929;
    color: #fff;
}

.btns-black:hover {
    color: #fff;
    background-color: #333;
}

/*Custom Anmation*/
.bgburns .slides li:nth-of-type(odd) .slide-background {
    animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate
}

.bgburns .slides li:nth-of-type(even) .slide-background {
    animation: bgburnsWest 25s linear infinite alternate;
    -webkit-animation: bgburnsWest 25s linear infinite alternate
}

@keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px)
    }
}

@-webkit-keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px)
    }
}

@keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px)
    }
}

@-webkit-keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px)
    }
}

@media all and (max-width: 767px) {
    .flex-direction-nav {
        display: none
    }

    .bgburns .slides li .slide-background {
        -webkit-animation: none !important;
        animation: none !important
    }
}

#page_scroller {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    cursor: pointer;
    color: #FFF;
    padding: 10px 11px;
    font-size: 12px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    z-index:1000 !important;
}

.margin-t-10 {
    margin-top: 10px;
}

.checkbox {
    padding-left: 3px;
    margin-bottom: 5px;
}

.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 8px;
    color: #4e4d4d;
    font-size: 15px;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: -1px;
    margin-left: -22px;
    border: 2px solid #cccccc;
    border-radius: 0;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #fff;
}

.advanced-search .checkbox {
    padding-left: 5px;
    margin-bottom: 5px;
}

input[type=checkbox]:checked + label:before {
  /*  display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    text-align: center;
    line-height: 16px;
    font-weight: 300;*/
}

.advanced-search ul {
    padding: 0;
    margin: 0;
}

.advanced-search ul li {
    list-style: none;
    padding: 5px 0;
    color: #787878;
    font-size: 15px;
    line-height: 30px!important;
    border-bottom: 1px dotted #DDD;
    text-align: right;
    font-weight: 500;
}

.advanced-search ul li:last-child{
    border-bottom: transparent;
}

.advanced-search ul span {
    font-weight: 500;
    float: left;
    color: #474646;
}

.as-2{
    display: none;
}

/* Col pad **/
.col-pad {
    padding: 0;
}

/** Solid buttons colors **/
.button-default {
    color: #555;
    background: #DDD;
}

.button-default:hover {
    color: #333;
    background: #DDD;
}

.button-theme {
    color: #FFF !important;
    outline: none;
}

.button-theme:hover {
    color: #fff;
}

.button-theme-2{
    background: #000;
}

.read-more {
    font-weight: 600;
    font-size: 15px;
}

.read-more:hover {
    color: #37404d;
}

.button-section .btn {
    margin-bottom: 5px;
}

.form-control:focus {
    border-color: #e0e0e0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.active, .btn:active {
    box-shadow: none;
}

/** BTN 3 **/
.btn-3 {
    color: #fff;
    display: inline-block;
    position: relative;
    z-index: 5;
    transition: .9s ease;
    padding: 0 47px 0 25px;
    line-height: 46px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Prompt', sans-serif;
}

.btn-3 .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.btn-3 .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-3:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .9s ease;
    z-index: -1;
}

.btn-3:hover {
    background: transparent!important;
}

.btn-3:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.btn-3:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

/** BTN 4 **/
.btn-4 {
    padding: 0 47px 0 25px;
    line-height: 46px;
    position: relative;
    display: inline-block;
    background: none;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    font-family: 'Prompt', sans-serif;
    text-transform: uppercase;
}

.btn-4 span {
    position: relative;
    z-index: 1;
}

.btn-4:hover span{
    color: #fff!important;
}

.btn-4 .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.btn-4 .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-4:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: transparent;
    -moz-transition: ease all 0.9s;
    -o-transition: ease all 0.9s;
    -webkit-transition: ease all 0.9s;
    transition: ease all 0.9s;
    width: 0;
    height: 100%;
}

.btn-4 span:hover {
    color: #fff!important;
}

.btn-4:hover {
    color: #ffffff!important;
}

.btn-4:hover:before {
    width: 100%;
    color: #fff!important;
}

/** BTN 5 **/
.btn-5 {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    color: #ffffff;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    padding: 14.5px 30px 11.5px;
    height: 50px;
    font-weight: 500;
    font-size: 15px;
    border-radius: 3px;
    text-transform: uppercase;
}

.btn-5 i::before {
    position: relative;
    font-size: 18px;
    top: 3px;
    padding-left: 5px;
}

.btn-5:hover {
    color: #ffffff;
}

.btn-5:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.btn-5:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

/** BTN 6 **/
.btn-6 {
    color: #fff;
    display: inline-block;
    position: relative;
    z-index: 5;
    transition: .9s ease;
    padding: 0 25px 0 25px;
    line-height: 41px;
    border-radius: 3px;
    font-family: 'Prompt', sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.btn-6 .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.btn-6 .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-6:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .9s ease;
    z-index: -1;
}

.btn-6:hover {
    background: transparent!important;
}

.btn-6:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.btn-6:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.btn-6{
    background: #fff;
    border: 2px solid #fff;
}

.btn-6:hover {
    color: #fff;
    border: 2px solid #fff;
}

.btn-6:before {
    background: #fff;
}

/** BTN 7 **/
.btn-7 {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    color: #000;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    padding: 12px 35px 11px;
    height: 45px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 3px;
    text-transform: uppercase;
    background: #fff;
}

.btn-7 i::before {
    position: relative;
    font-size: 18px;
    top: 3px;
    padding-left: 5px;
}

.btn-7:hover {
    color: #ffffff;
}

.btn-7:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.btn-7:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

/** Btn 8 **/
.btn-8 {
    position: relative;
    padding: 14px 25px 16px;
    transition: all .2s ease;
}

.btn-8:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    transition: all .3s ease;
}

.btn-8 span {
    position: relative;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    color: #fff;
}

.btn-8:hover:before {
    width: 100%;
    border-radius: 3px;
}

.btn-8:hover span{
    color: #fff;
}

/** Btn 9 **/
.btn-9{
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: 0.5s;
    overflow: hidden;
    padding: 0 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 48px;
    border-radius: 0;
    letter-spacing: 0.5px;
}

.btn-9:hover{
    color: #fff;
}

.btn-9 span{
    position: absolute;
    display: block;
}

.btn-9 span:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    animation: animate1 1s linear infinite;
}

.btn-9 span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}

.btn-9 span:nth-child(3){
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    animation: animate3 1s linear infinite;
    animation-delay: 0.50s;
}

.btn-9 span:nth-child(4){
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes animate1{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}

@keyframes animate2{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}

@keyframes animate3{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}

@keyframes animate4{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100%;
    }
}




.btn-9{
    color: #ffb606;
}

.btn-9:hover{
    background: #ffb606;
}

.btn-9 span:nth-child(1){
    background: linear-gradient(90deg,transparent,#ffb606);
}

.btn-9 span:nth-child(2){
    background: linear-gradient(180deg,transparent,#ffb606);
}

.btn-9 span:nth-child(3){
    background: linear-gradient(270deg,transparent,#ffb606);
}

.btn-9 span:nth-child(4){
    background: linear-gradient(360deg,transparent,#ffb606);
}

/** Border buttons colors **/
.border-btn-sm {
    cursor: pointer;
    padding: 5px 12px;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #555;
    background: #DDD;
    transition: .5s;
    border-radius: 2px;
    border: none;
}

.border-button-default {
    color: #555;
    background: #DDD;
}

.border-button-default:hover {
    color: #333;
    background: #DDD;
}

.border-button-theme {
    background: transparent;
}

.border-button-theme:hover {
    color: #fff;
}

.alert .close {
    line-height: 15px;
}

.panel-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.alert {
    font-size: 16px;
    border-radius: 0;
    padding: 13px 20px;
    margin-bottom: 10px;
}

.progress {
    margin-bottom: 20px;
    height: 22px;
}

/* Table */
.table-bordered .ass th{
    font-weight: 500;
}

.table-bordered {
    font-family: 'Prompt', sans-serif;
    font-weight: 400;
    color: #5a5959;
    font-size: 15px;
}

.table>:not(caption)>*>* {
    padding: 20px;
}

/* List group item */
.list-group-item {
    padding: 14px 20px;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

/* Below tabs mode */
.tab-style-2-line.tabs-below > .nav-tabs > li {
    border-top: 4px solid transparent;
}

.tab-style-2-line.tabs-below > .nav-tabs > li > a {
    margin-top: 0;
}

.tab-style-2-line.tabs-below > .nav-tabs > li:hover {
    border-bottom: 0;
    border-top: 4px solid #b2b1b1;
}

.tab-style-2-line.tabs-below > .nav-tabs > li.active {
    margin-bottom: -2px;
    border-bottom: 0;
    border-top: 4px solid #b2b1b1;
}

.tab-style-2-line.tabs-below > .tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

/** Top header start **/
.top-header{
  /*  display: inline-block;*/
    width: 100%;
    font-family: 'Prompt', sans-serif;
}

.top-header.bg-active{
    background: #101e5d;
}

.top-header .list-inline {
    padding: 10px 0;
}

.list-inline a {
    color: #fff;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.9;
}

.top-header .list-inline a:last-child{
    margin-right: 0;
}

.list-inline a i {
    margin-right: 5px;
}

.top-header ul li {
    padding: 0 7px;
    height: 45px;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    color: #aaa;
    font-size: 14px;
}

.top-header ul li:last-child{
    padding-right: 0;
}

.top-header .sign-in, .top-header .sign-in:hover {
    line-height: 36px;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.9;
}

.top-header ul li a {
    color: #fff;
}

/** Main header start **/
.main-header .company-logo {
    padding: 15px 0;
    float: left;
}

.main-header .company-logo-2 {
    padding: 15px 0;
    float: left;
}

.header-with-top{
    background: rgba(12, 12, 12, 0.18);
}

.main-header .pad-0{
    padding: 0;
}

.main-header .btn-md{
    margin-top: 20px;
}

.main-header .company-logo img {
    height: 50px;
}

.main-header .company-logo-2 img {
    height: 50px;
}

.logo img{
    height: 50px;
}

.header-ml {
    margin-left: 20px;
}

.header-transparent {
    position: absolute;
    z-index: 9;
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, .2)
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
    color: #F26623;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
    /* padding: 35px 15px; */
    line-height: 20px;
    color: #F26623;
    opacity: 1;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-family: 'Prompt', sans-serif;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link i {
    margin-right: 5px;
}

.main-header .navbar-expand-lg .navbar-nav .link-color:hover {
    background: transparent;
}

.main-header .navbar-expand-lg .navbar-nav .link-color {
    padding: 9px 20px 7px!important;
    border-radius: 50px;
    margin: 26px 0 0 10px;
}

.main-header .navbar-expand-lg .user-account {
    padding: 30px 15px;
    font-size: 14px;
    font-weight: 600;
}

.main-header .navbar-expand-lg .user-account li {
    border: none;
    display: inline-block;
    font-size: 14px;
}

.main-header .navbar-expand-lg .user-account li a {
    color: #212121;
}

.main-header .navbar {
    padding: 0;
}

.main-header .form-inline .submit {
    padding: 7px 22px;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 15px;
    font-weight: 600;
}

.main-header .dropdown-menu {
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 13px;
    color: #212529;
    border: none;
    -webkit-transition: opacity .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s, transform .4s ease 0s, visibility .4s linear 0s;
    transition: opacity .4s ease 0s, transform .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
    border-radius: 0;
    border-top: solid 1px #f1f1f1;
}

.dropdown .dropdown-item:hover > .dropdown-menu {
    display: block;
    -webkit-transition: opacity .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s, transform .4s ease 0s, visibility .4s linear 0s;
    transition: opacity .4s ease 0s, transform .4s ease 0s, visibility .4s linear 0s, -webkit-transform .4s ease 0s;
}

.main-header .dropdown-menu .dropdown-submenu .dropdown-menu{
    margin: -1px;
}

.main-header .dropdown-menu a {
    padding: 12px 20px;
    color: #000;
    transition: all .3s ease-in-out;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    background: transparent!important;;
    font-family: 'Prompt', sans-serif;
}

.main-header .dropdown-menu a:hover {
    background: transparent;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

/** Megamenu **/
.megamenu-li {
    position: static;
}

.megamenu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
}

.megamenu-area {
    padding: 30px 30px 5px;
    z-index: 2;
}

.megamenu-title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-family: 'Prompt', sans-serif;
}

.megamenu-section {
    margin-bottom: 25px;
}

.megamenu-area .dropdown-item {
    padding: 0 !important;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
}

.megamenu-section p {
    font-size: 13px;
    color: #777;
}

.sb-2{
    display: none;
}

/** Banner**/
#banner {
    position: relative;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
}



.item-bg {
    /*background: url("../img/banner-1.png");*/
    height: 100vh;
    /*animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate;*/
}

.banner .btn-3{
    margin-bottom: 3px;
}

.banner .btn-4{
    margin-bottom: 3px;
}

.banner-min-height {
    height: 720px;
}

.banner .carousel-content.banner-info {
    margin: 0 auto;
    letter-spacing: 1px;
    right: 0;
    left: 0;
    z-index: 999;
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.banner .carousel-content.banner-info-2 {
    margin: 0 auto;
    right: 0;
    left: 0;
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 100%;
    text-align: center;
}

.banner .carousel-content.banner-info-2 .search-fields{
    border-radius: 0;
}

.banner .carousel-content.bi-2{
    padding-top: 50px;
}

.carousel-item .text-right {
    float: right;
}

.banner .banner-slider-inner{
    padding: 0;
    margin: 0;
}

.banner .banner-slider-inner h1 {
    margin-bottom: 10px;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner .banner-slider-inner .name_wrap h1 {
    position: relative;
    color: #fff;
    line-height: 1.2;
    display: inline-block;
    overflow: hidden;
}

.banner .banner-slider-inner .name_wrap h1 span {
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    padding-left: 2px;
}

.banner .banner-slider-inner h2 {
    color: #fff;
    margin-bottom: 15px;
    font-size: 55px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner .btn-lg {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.btn-white-lg-outline {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.banner h4{
    font-weight: 500;
    margin-bottom: 0;
    font-size: 40px;
}

.banner .btn-lg {
    cursor: pointer;
    padding: 12px 35px 11px 35px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.banner .banner-slider-inner .max-w {
    max-width: 490px;
}

.banner .banner-slider-inner p {
    color: #fff;
    line-height: 1.6;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner .banner-slider-inner h2 span {
    font-weight: 900;
}

.banner .banner-slider-inner .typed-text {
    font-size: 62px;
}

.banner .banner-slider-inner {
    text-shadow: none;
    right: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: rgba(36, 42, 53, 0.7);
}

.banner .btn-lg {
    margin: 3px;
}

.banner-bg-color .banner-slider-inner {
    background-color: rgba(255, 255, 255, 0.1);
}

.banner .carousel-bg .carousel-item {
    height: 100vh;
    background: #3e4a5b;
}

.banner_video_bg {
    max-height: 720px;
}

.banner-slider-inner .show-more-options{
    margin: 0;
    color: #fff!important;
    float: left;
    border-radius: 50px;
    margin-top: 25px;
}

.banner-slider-inner #options-content {
    background: #fff;
    padding: 20px 20px 7px;
    border-radius: 3px;
}

.banner-slider-inner .show-more-options i{
    color: #fff;
}

.banner .slider-mover-left {
    width: 30px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    left: 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.36);
}

.banner .slider-mover-left i {
    font-size: 20px;
    color: #fff;
}

.banner .slider-mover-right {
    width: 30px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    right: 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.36);
}

.banner .slider-mover-right i {
    font-size: 20px;
    color: #fff;
}

.pattern-overlay {
    /*background-color: rgba(0, 0, 0, 0.35);
    height: 100vh;*/
}

.banner .carousel-control-next, .carousel-control-prev{
    opacity: 1;
}

.banner .carousel-control-next, .carousel-control-prev {
    background: #423b3b57;
    width: 60px;
    height: 60px;
    border-radius: 2px;
    top: 45%;
    margin: 5px;
}

.banner .carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.banner-content2{
    max-width: 700px;
    margin: 0 auto;
    padding: 30px;
    -webkit-box-shadow: 0px 0px 0px 10px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 10px rgba(255,255,255,0.2);
    background: rgba(0, 0, 0, 0.36);
}

.banner-content2 .btn-round {
    border-radius: 3px;
}

.banner .banner-slider-inner .banner-content2 h2 {
    text-transform: none;
    font-weight: 600;
}

.pattern-overlay {
    /*background-color: rgba(0, 0, 0, 0.35);
    height: 100vh;*/
}

.banner_video_bg .carousel-item {
    height: 100vh;
}

#particles-banner {
    width: 100%;
    height: 100vh;
    background-color: rgba(36, 42, 53, 0.7);
    position: absolute;
}

#particles-banner-wrapper .banner-slider-inner {
    background-color: transparent;
}

.banner-sty-2 .banner-slider-inner h1 {
    font-size: 50px;
}

.banner .bs{
    top: 40%;
    position: absolute;
    width: 100%;
}

.banner h3 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner h5{
    color: #fff;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner h6{
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
}

.banner .price-box-3 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner .btn-5{
    border-radius: 50px;
}

.banner .price{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
}

.banner .monthly {
    position: absolute;
    padding: 10px 15px 15px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    border-radius: 5px;
}

.banner .fresh {
    margin-left: 115px;
    padding-top: 12px;
}

.banner .price-box-3 sup {
    font-size: 15px;
    position: relative;
    top: -25px;
    color: #fff;
    font-weight: 600;
}

.banner .price-box-3 span {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
}

.banner .typing > *{
    overflow: hidden;
    white-space: nowrap;
    animation: typingAnim 3s steps(50);
}

@keyframes typingAnim {
    from {width:0}
    to {width:100%}
}
/** Search start **/
#search-style-1 {
    transition: all 0.4s;
}

#search-style-2{
    transition: all 0.4s;
    position: absolute;
    width: 100%;
}

#search-style-3{
    width: 100%;
    z-index: 999;
    padding: 30px 0 100px;
}

.row-2{
    margin-right: -7.5px;
    margin-left: -7.5px;
}

.row-3{
    margin-right: 0;
    margin-left: 0;
}

.item-bg {
    height: 100vh;
}

.search-area {
    display: none;
    width: 100%;
    bottom: 80px;
    text-align: left;
    z-index: 999;
    padding-top: 30px;
}

.search-area-2 {
    padding: 30px 0 0;
}

.bg-grea{
    background: #e6e6e6;
}

.bg-grea-2{
    background: #fff;
}

.bg-grea-3{
    background: #f9f9f8;
}

.bg-grea-4{
    background: #fff;
}

.search-area-inner {
    margin: 0 auto;
}

.search-area-inner h1{
    font-size: 25px;
    margin-bottom: 15px;
}

.search-area-inner .col-pad2{
    padding: 0 7.5px;
}

.search-area-inner .show-more-options {
    font-weight: 500;
    font-size: 15px;
    display: block;
    margin-bottom: 0;
}

.search-contents {
    border-radius: 4px;
    transition: 0.9s ease-out;
}

.show-search-area {
    z-index: 9999;
}
.search-contents{
    transition: all 0.4s;
    display: block;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
    font-size: 13px;
    color: #777;
    font-weight: 400;
}

.search-options-btn, .search-options-btn:hover {
    padding: 12px 20px 10px 20px;
    font-size: 13px;
    font-weight: 400;
    color: #FFF!important;
    cursor: pointer;
    z-index: 0;
    letter-spacing: 1px;
    position: absolute;
    bottom: 10px;
    border-radius: 50px;
}

.search-options-btn, .search-options-btn i {
    margin-left: 5px;
}

.search-options-btn .search-options{
    float: left;
}

.search-options-btn .icon{
    float: left;
}

.search-area label {
    font-size: 12px;
    color: #555;
    font-weight: 600;
}

.search-area .form-group {
    margin-bottom: 25px;
}

.search-fields {
    min-height: 45px;
    padding: 0;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #e8e8e8;
    background: #fff;
}

.inline-search-area .search-fields{
    border-right: 1px solid #e0e0e0!important;
}

.form-control {
    padding-left: 15px;
}

.search-fields button {
    border-radius: 1px;
    box-shadow: none;
    border: none;
    background: #fff !important;
}

.search-button {
    cursor: pointer;
    padding: 14px 26px;
    letter-spacing: 0.5px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFF;
    transition: .5s;
    border-radius: 2px;
    border: none;
    width: 100%;
    outline: none;
}

.search-button:hover {
    color: #fff;
}

.inline-search-area .search-col {
    padding: 0;
    position: relative;
}

.inline-search-area .icon-append {
    color: #949494;
    font-size: 15px;
    left: 10px;
    position: absolute;
    top: 18px;
}

.inline-search-area .search-col:first-child .form-control {
    border-radius: 3px 0 0 3px;
}

.inline-search-area .search-col .form-control,
.inline-search-area button.btn-search {
    border-radius: 0;
    border: 0;
    height: 55px;
    margin-bottom: 0;
    font-size: 14px;
    color: #777;
    font-width: 500;
    text-transform: uppercase;
}

.inline-search-area .search-col .form-control,
.inline-search-area button.btn-search i{
    color: #fff;
}

.inline-search-area .search-col .form-control {
    padding-top: 12px;
}

.inline-search-area button.btn-search {
    padding-top: 10px;
    width: 100%;
}

.inline-search-area .btn-search {
    border-radius: 0 3px 3px 0 !important;
    margin: 0;
}

.inline-search-area .btn-search strong {
    color: #fff;
    margin-left: 5px;
}

.inline-search-area .search-fields,
.inline-search-area .search-fields:hover {
    border: none;
    padding: 0 !important;
}

.inline-search-area .bootstrap-select button {
    padding: 16.5px 20px 16px 15px!important;
    border-radius: 0;
    margin: 0 !important;
}

.inline-search-area .dropdown-toggle::after {
    margin-left: -4px;
    top: 28px;
    right: 10px;
    position: absolute;
}

.main-header .inline-search-area .dropdown-menu .dropdown-item:hover {
    -webkit-transform: none;
    transform: none;
}

.inline-search-area .btn-light:hover {
    color: #636363;
}

.inline-search-area .bootstrap-select .dropdown-toggle .filter-option {
    /* position: absolute; */
    top: 1px;
}

.inline-search-area .form-control{
    border-right: solid 1px #5a5a5a !important;
}

/** Portfolio start **/
.filteriz-navigation {
    margin:20px auto 26px;
}

.filteriz-navigation li {
    padding: 7px 25px 5px;
    background: #fff;
    color: #000000;
    font-size: 17px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin: 0 1px 4px;
    font-weight: 500;
    border-radius: 5px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transform: skew(-21deg);
    font-family: 'Prompt', sans-serif;
}

.filteriz-navigation li.active span{
    color: #fff;
}

.filteriz-navigation li.active {
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}

.filteriz-navigation li:hover span{
    color: #fff;
}

.filteriz-navigation li span {
    transform: skew(21deg);
    display: flex;
    z-index: 2;
}

.filteriz-navigation li:last-child::after{
    display: none;
}

.filteriz-navigation li{
    display: inline-block;
}

.portfolio-item, .portfolio-item:focus {
    outline: none;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
}

.portfolio-item img, .portfolio-item img:focus {
    outline: none;
    width: 100%;
    height: 100%;
}

.portfolio-item:hover img {
    zoom: 1;
    filter: alpha(opacity=20);
    -webkit-opacity: 0.1;
    opacity: 0.1;
}

.portfolio-item:hover .portfolio-content {
    display: inline;
    -webkit-transition-delay: 0.50s;
    transition-delay: 0.50s;
    background-color: rgba(0, 0, 0, 0.90);
    color: #fff;
    z-index: -999;
}

.portfolio-content {
    display: none;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    color: #1c1c1c;
    height: 100%;
    position: absolute;
    right: 0;
}

.portfolio-content-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.portfolio-content p {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}

.photo-gallery .col-pad{
    padding: 0 7.5px 0;
}

.photo-gallery .filtr-item {
    padding: 0 15px;
}

.cars{
    padding: 0 7.5px 0;
}

.overview-box p {
    color: #333;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.overview {
    z-index: 9;
    cursor: default;
    overflow: hidden;
    text-align: center;
    position: relative;
    margin-bottom: 16px;
    box-shadow: 0 0 3px #ddd;
}

.overview .mask, .overview .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 25%;
    left: 0;
}

.overview img {
    display: block;
    position: relative;
    width: 100%;
    height: 232px;
}

.overview .big-img {
    height: 480px;
}

.overview-box img {
    left: 10px;
    margin-left: -10px;
    position: relative;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    border: none;
}

.overview-box:hover > img {
    -webkit-transform: scale(2) rotate(10deg);
    transform: scale(2) rotate(10deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.overview h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 18px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
    text-shadow: none;
}

.overview-box h2 {
    color: #333;
    margin: 0 20px 0;
    background: transparent;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.overview a.btn {
    display: inline-block;
    overflow: hidden;
    text-transform: uppercase;
}

.overview-box a.btn {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
    padding: 4px 20px;
}

.overview-box:hover h2,
.overview-box:hover p,
.overview-box:hover a.btn {
    -webkit-transform: scale(1);
    transform: scale(1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.most-popular-places .col-pad {
    padding: 0 8px;
}

/** Bootstrap select start **/
.bootstrap-select {
    width: 100% !important;
}

.bootstrap-select button {
    color: #636363;
    border-radius: 2px;
    outline: none;
    padding: 13px 15px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.btn-light{
    color: #636363!important;
}

.sb-7 .bootstrap-select button {
    color: #636363;
    border-radius: 50px;
    background: #f7f7f7!important;
    padding: 14px 30px 12px 20px;
}

.sb-7 .bootstrap-select button{
    box-shadow: none;
}

.sb-7 .search-fields {
    min-height: 45px;
    padding: 0;
    box-shadow: none;
    border: 1px solid #f7f7f7;
    background: #f7f7f7;
    border-radius: 50px!important;
}

.sb-7 .btn-md{
    border-radius: 50px!important;
}

.sb-8 .bootstrap-select button {
    background: #fff!important;
}

.search-compare .bootstrap-select button {
    color: #636363;
    outline: none;
    width: 100%;
    padding: 14px 35px 12px 20px;
    font-size: 16px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.search-compare .search-button {
    padding: 14px 26px 14px;
}

.bootstrap-select button:hover{
    color: #636363;
}

.banner  .sb-7 .search-fields {
    border-radius: 3px!important;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
    color: #636363!important;
}

.bootstrap-select .dropdown-menu li {
    padding: 0;
}

.bootstrap-select .dropdown-menu li a {
    padding: 10px;
}

.bootstrap-select .dropdown-menu{
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: none;
    border-radius: 0;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 12px;
}

.bootstrap-select .dropdown-menu li a span{
    font-weight: 400!important;
    color: #636363!important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent;
}

.bootstrap-select .dropdown-menu li a {
    padding: 8px 20px;
    font-weight: 400;
    font-size: 15px;
    outline: none;
    border: 0 !important;
}

.bootstrap-select .dropdown-menu li a:hover {
    border: none;
    font-weight: 400;
    padding: 8px 20px;
    font-size: 15px;
    background: #f9f9f9;
}

.bootstrap-select .btn-default.active,
.bootstrap-select .btn-default:active,
.bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: #eee;
    border-color: transparent !important;
    box-shadow: none !important;
}

.bootstrap-select .btn-default:hover {
    background: transparent !important;
    color: #777 !important;
    box-shadow: none !important;
}

.bootstrap-select .btn-default.active,
.bootstrap-select .btn-default:active,
.bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: transparent !important;
    border-color: #adadad;
}

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: transparent !important;
    box-shadow: none !important;
    border-color: #adadad;
}

.bootstrap-select .dropdown-menu.inner {
    border: 0;
    border-top: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.search-box-2 .bootstrap-select .dropdown-menu{
    margin-top: -2px!important;
}

.search-box-4 .bootstrap-select .dropdown-menu{
    margin-top: -1px!important;
}

.bst .inline-search-area .bootstrap-select .dropdown-menu{
    margin-top: -2px!important;
}

.sb-5 .bootstrap-select .dropdown-menu{
    margin-top: -1px!important;
}

.btn-light:hover {
    color: #999;
}

.advanced-search2 .btn-light:hover {
    color: #636363;
}

.advanced-search2 .form-group{
    margin-bottom: 20px;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}

.bootstrap-select .dropdown-menu > .active > a,
.bootstrap-select .dropdown-menu > .active > a:focus,
.bootstrap-select .dropdown-menu > .active > a:hover {
    text-decoration: none;
    background-color: #fff;
    outline: 0;
}

.bootstrap-select .bs-actionsbox, .bootstrap-select .bs-donebutton, .bs-searchbox {
    padding: 4px 8px;
    background: #EEE;
    border-bottom: 1px solid #d5d5d5;
}

.bootstrap-select .bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
    height: 36px;
    border: 1px solid #ddd;
    box-shadow: none;
}

.bootstrap-select .dropdown-toggle .filter-option-inner {
    overflow: hidden;
    font-size: 15px;
    font-weight: 500;
}

.bootstrap-select .dropdown-menu li a.active:hover .text {
    color: #515151;
}

/** Main title start **/
.main-title {
    margin-bottom: 50px;
    text-align: center;
}

.main-title h1 {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #000000;
}

.main-title h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
}

.main-title p{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 400;
}

/** Title border area start **/
.title-border{
    height: 5px;
    width: 120px;
    background: transparent;
    border-radius: 10px;
    margin: 0 auto;
}

.title-border-inner {
    height: 5px;
    width: 15px;
    animation: pulse 3s ease infinite alternate, nudge 5s linear infinite alternate;
    border-radius: 10px;
    float: left;
    margin: 0 5px;
}

.title-border.tb2{
    margin: 0 auto 0 0;
}

@keyframes nudge {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(50px, 0);
    }
    80% {
        transform: translate(20px, 0);
    }
}

/** Main title 2 start **/
.main-title-2 {
    margin-bottom: 50px;
    z-index: 999;
    color: #fff;
    opacity: 0.9;
}

.main-title-2 h1 {
    color: #fff;
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 15px;
    font-family: 'Prompt', sans-serif;
    z-index: 20;
    text-transform: uppercase;
}

.main-title-2 p{
    color: #fff;
    font-size: 18px;
    opacity: 0.9;
    margin-bottom: 0;
}

/** Property box start **/
.car-thumbnail {
    position: relative;
}

.car-thumbnail:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 8;
    background: linear-gradient(to top,rgba(47, 45, 45, 0.9) 0%,rgba(0, 0, 0, 0.45) 35%,rgba(2, 2, 2, 0) 60%,rgba(0,0,0,0) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.carbox-overlap-wrapper {
    visibility: hidden;
    background: rgb(12 62 185 / 51%);
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 998;
    color: #FFF;
    left: 0;
    top: 0;
}

.car-thumbnail:hover > .carbox-overlap-wrapper {
    visibility: visible;
    top: 0;
    cursor: pointer;
    transition: all .15s linear;
}

.overlap-box{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.carbox-overlap-wrapper h2 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Prompt', sans-serif;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
}

.carbox-overlap-wrapper h4 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Prompt', sans-serif;
    line-height: 26px;
    color: #fff;
}

.car-magnify-gallery {
    display: inline-block;
}

.overlap-btns-area img{
    display: none;
}

.overlap-btn {
    border: solid 1px #fff;
}

.overlap-btn {
    width: 36px;
    height: 36px;
    line-height: 35px;
    display: inline-block;
    color: #FFF;
    margin: 16px 1px;
    border-radius: 3px;
    font-size: 15px;
    text-transform: uppercase;
    display: inline-block;
}

.overlap-btn:hover {
    text-decoration: none;
    color: #FFF;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    /* ...and now for the proper property */
    transition: .5s;
}


/** Car box start **/
.car-box {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.car-box:hover{
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.car-box .car-image {
    overflow: hidden;
    position: relative;
}

.car-box .facilities-list {
    position: absolute;
    bottom: 0px;
    font-size: 15px;
    width: 100%;
    padding: 9.5px 0;
    text-align: center;
    background: rgb(255 180 0 / 65%);
    z-index: 999;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.car-box:hover .facilities-list{
    width: 90%;
    margin: 0 5%;
    bottom: 10px;
    background: #fff;
    color: #000;
    border-radius: 5px;
}

.car-box .facilities-list ul li {
    display: inline-block;
    color: #fff;
    font-weight: 500;
    line-height: 26px;
    width: 33.33%;
    float: left;
    color: #fff;
    text-align: center;
}

.car-box:hover .facilities-list ul li{
    color: #000;
}

.car-box .car-image::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.car-box .car-image img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 999;
}

.car-box .car-image::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.car-box:hover .car-image::before, .car-box:hover .car-image::after {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    visibility: visible;
    background: rgb(12 62 185 / 51%);
}

.car-box .detail {
    padding: 25px 25px;
}

.car-box .detail .title {
    font-size: 22px;
    margin-bottom: 10px;
}

.car-box .detail .location {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
}

.car-box .detail .location a{
    color: #5a5959;
}

.car-box .footer {
    font-size: 15px;
    border-top: solid 1px #efefef;
    background: #fff;
}

.car-box .ratings {
    line-height: 49px;
    padding-left: 20px;
}

.car-box .footer .ratings i {
    color: orange;
}

.car-box .footer .price {
    line-height: 49px;
    padding-right: 20px;
    font-size: 18px;
    margin-bottom: 0;
}

/** MODAL BODY DESIGN STARTED**/
.car-model-2 .modal-raw {
    display: flex;
    background: #fff;
}

.car-model-2 .modal-left, .modal-right {
    flex: 1;
}

.car-model-2 .car-modal .modal-left {
    padding: 0;
    color: #fff;
}

.car-model-2 .car-modal .modal-content {
    border: none;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.car-model-2 .car-modal .modal-header {
    background: transparent;
    border: none;
    padding: 15px 0;
}

.car-model-2 .car-modal .modal-header .modal-title {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
}

.car-model-2 .car-modal .modal-header p {
    color: #fff;
    margin-bottom: 0;
}

.car-model-2 .car-modal .modal-header .close,
.car-modal .modal-header .close:focus {
    padding: 8px 13px;
    outline: none;
    margin: -60px -1px -15px auto;
    background: #3a3a3a;
    opacity: 1;
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
}

.car-model-2 .car-modal .modal-body {
    padding: 0 15px;
    background: #fff;
}

.car-model-2 .modal-right {
    padding: 0;
}

.car-model-2 .modal-left{
    padding: 0;
}

#modalCarousel .carousel-item {
    max-height: 240px;
}

.car-model-2 .modal-left-content {
    background: #fff;
}

.car-model-2 .modal-left-content img {
    width: 100%!important;
    height: 100%!important;
}

.car-model-2 .modal-left-content .control {
    width: 30px;
    height: 30px;
    position: absolute;
    text-align: center;
    color: #FFF;
    font-size: 24px;
    line-height: 30px;
    bottom: 0px;
    opacity: .5;
}

.car-model-2 .modal-left-content .carousel-inner {
    display: flex;
}

.car-model-2 .modal-left-content .carousel-inner .item {
    flex: 1;
    width: 100%;
}

.car-model-2 .modal-left-content .carousel-inner .item img{
    width: 100%;
}

.car-model-2 .modal-left-content iframe {
    border: none;
    width: 100%;
    display: block;
}

.car-model-2 .modal-left-content .control:hover {
    opacity: 1;
}

.car-model-2 .modal-left-content .control-prev {
    right: 30px;
}

.car-model-2 .modal-left-content .control-next {
    right: 0;
}

.car-model-2 .modal-left-content .description {
    padding: 30px 30px 45px;
}

.car-model-2 .modal-left-content p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;
}

.car-model-2 .modal-left-content .description h3 {
    font-size: 20px;
    margin: 0 0 15px;
    font-weight: 600;
}

.car-model-2 .modal-right-content .bullets {
    color: #5a5959;
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    margin-bottom: 20px;
    columns: 2;
}

.car-model-2 .modal-right-content .bullets li {
    margin-bottom: 5px;
    position: relative;
    list-style: none;
    padding-left: 0;
}

.car-model-2 .modal-right-content .bullets li i {
    left: 0;
    position: absolute;
    top: 0;
}

.car-model-2 .modal-right-content {
    padding: 30px;
    background: #fff;
}

.car-model-2 .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 15px 20px;
}

.car-model-2 .modal-backdrop.show {
    opacity: 0.7;
}

.car-model-2 .modal-header {
    background: transparent;
    border: none;
    padding: 5px 0;
}

.car-model-2 .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
}

.car-model-2 .modal-right-content h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 15px;
}

.car-model-2 .modal-right-content dl {
    margin-bottom: 20px;
    font-size: 14px;
    color: #5a5959;
}

.car-model-2 .modal-right-content dl dt {
    float: left;
    font-weight: 500;
}

.car-model-2 .modal-right-content dl dd {
    text-align: right;
    margin-bottom: 8px;
}

.car-model-2 .modal-right-content p{
    font-size: 15px;
}

.car-model-2 .modal{
    z-index: 9999;
}

.car-model-2 .ratings-box {
    margin-right: 4px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 3px;
}

.car-model-2 .ratings-box {
    color: #fff!important;
}

.car-model-2 .ratings-2 i {
    color: orange;
}

.car-model-2 .ratings-2{
    font-size: 14px;
    color: #fff!important;
    font-weight: 400;
}

.car-model-2 .modal-body .btn-md {
    padding: 9px 25px 7px 25px;
    letter-spacing: 0.5px;
    font-size: 13px;
    border-radius: 3px;
}

.car-model-2 h4{
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}

.car-model-2 h5{
    font-size: 15px;
    color: #fff;
    font-family: 'Prompt', sans-serif;
    font-weight: 400;
}

.car-model-2 .item{
    position: relative;
}

.car-model-2 .sobuz {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.car-model-2 .close{
    opacity: 1!important;
    background: #fff;
    color: #000;
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border: none;
    text-align: center;
    margin: 0;
}

.car-model-2 .modal-dialog {
    margin: 100px auto;
}

.car-model-2 .price-box {
    text-align: left;
    font-size: 25px;
    position: inherit;
    left: 0;
    bottom: 30px;
    font-weight: 600;
    opacity: 1;
    font-family: 'Prompt', sans-serif;
}

.car-model-2 img{
    width: 100%;
}
/** MODAL BODY DESIGN ENDED **/

/** Price box start**/
.price-box {
    color: #fff;
    text-align: right;
    font-size: 17px;
    position: absolute;
    bottom: 40px;
    right: 20px;
    z-index: 20;
    font-weight: 600;
}

.price-box span{
    color: #fff;
}

.price-box .del{
    color: red;
    font-size: 15px;
}

.price-box-2 {
    font-size: 28px;
    font-weight: 700;
    position: absolute;
    bottom: 25px;
    z-index: 998;
    font-family: 'Prompt', sans-serif;
}

.price-box-2 sup {
    font-size: 15px;
    position: relative;
    top: -12px;
    color: #fff;
    font-weight: 400;
    opacity: 0.9;
}

.price-box-2 span {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    opacity: 0.9;
}

.price-box-3 {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Prompt', sans-serif;
}

.price-box-3 sup {
    font-size: 15px;
    position: relative;
    top: -25px;
    color: #212121;
    font-weight: 600;
}

.price-box-3 span {
    font-size: 15px;
    color: #212121;
    font-weight: 600;
}

.heading-car .price-box-3 {
    font-size: 50px;
}

.tab-box p{
    margin-bottom: 0;
}

/** Tag start**/
.tag {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 20;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    padding: 7px 16px 5px;
    font-weight: 500;
    border-radius: 3px 3px 3px 0;
    font-family: 'Prompt', sans-serif;
}

.tag::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 10px solid transparent;
    bottom: -10px;
    left: 0;
}

.tag-2 {
    float: left;
    position: absolute;
    transform: rotate(-45deg);
    left: -60px;
    top: 30px;
    text-align: center;
    width: 200px;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    background: #292929;
    text-transform: uppercase;
}

.slide-2{
    margin-bottom: 50px;
}

/** Slick slider start**/
.slick-slider-area {
    position: relative;
}

.slick-slide-item {
    padding: 0 15px!important;
}

.slick-arrow-buton {
    position: absolute;
    top: 43%;
}

.slick-arrow-buton {
    width: 35px;
    height: 60px;
    line-height: 60px;
    top: 43%;
    border-radius: 5px;
    color: #000;
    z-index: 999;
    background: #fff;
    position: absolute;
    font-size: 22px;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 15%);
    cursor: pointer;
    text-align: center;
}

.slick-next {
    right: -15px;
}

.slick-prev{
    left: -15px;
}

.slick-slide, .slick-slide:focus * {
    outline: none !important;
}

*:focus {
    outline: none !important;
}


.sh-3.bg-active .navbar-expand-lg .navbar-nav .nav-link:hover{
    color: #fff;
}

/** Car box 2 start **/
.car-box-2 {
    background: #fff;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: 1;
}

.car-box-2 .car-thumbnail:before {
    background: linear-gradient(to top,rgba(47, 45, 45, 0.4) 0%,rgba(0, 0, 0, 0.1) 35%,rgba(2, 2, 2, 0) 60%,rgba(0,0,0,0) 100%);
}

.car-box-2:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.car-box-2 .detail .title {
    font-size: 24px;
    margin-bottom: 3px;
    font-weight: 500;
    text-transform: uppercase;
}

.car-box-2 .car-img img{
    width: 100%;
}

.car-box-2 .detail .custom-list{
    margin-bottom: 20px;
    font-family: 'Prompt', sans-serif;
}

.car-box-2 .detail .title a:hover {
    color: #343a40;
}

.car-box-2 .detail .location {
    margin-bottom: 15px;
    font-family: 'Prompt', sans-serif;
}

.car-box-2 .detail .location a {
    color: #5f5e5e;
    font-size: 15px;
    font-weight: 400;
}

.car-box-2 .price-box {
    z-index: 999;
    bottom: 10px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.car-box-2:hover .price-box{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.car-box-2 .tag{
    left: 30px;
}

.car-box-2 .listing-badges .featured {
    left: -51px;
}

.car-box-2 .listing-time {
    position: absolute;
    z-index: 9;
    top: 8px;
    left: 30px;
}

.car-box-2 .detail{
    padding: 0 30px;
}

.car-box-2 .facilities-list li {
    list-style: none;
    width: 33.33%;
    float: left;
    font-weight: 500;
    line-height: 35px;
    font-size: 15px;
}

.car-box-2 .facilities-list li i {
    font-size: 15px;
    font-weight: 500;
}

.car-box-2 .custom-list{
    margin-bottom: 20px;
}

.car-box-2 .custom-list li {
    font-size: 12px;
    display: inline-block;
    padding-right: 5px;
    text-transform: uppercase;
    font-weight: 600;
}

.car-box-2 .custom-list li a{
    color: #5a5959;
}

.car-box-2 .tag-2 {
    left: -50px;
    top: 20px;
    z-index: 999;
}

.car-box-2 .for {
    position: absolute;
    left: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 5.5px 15px 4.5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    top: 20px;
    z-index: 999;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.car-box-2 .price-box {
    z-index: 999;
    bottom: 10px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.car-box-2:hover .for{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

/** Car box 3 **/
.car-box-3 {
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: 1;
}

.car-box-3:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.car-box-3 .car-thumbnail {
    position: relative;
    display: block;
    overflow: hidden;
}

.car-box-3 .car-thumbnail:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(to top,rgb(35 35 37 / 66%) 0%,rgb(35 35 37 / 13%) 35%,rgb(255 255 255 / 0%) 60%,rgb(255 255 255 / 0%) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.car-box-3 .detail {
    padding: 30px 25px 20px;
}

.car-box-3 .custom-list {
    margin-bottom: 15px;
}

.car-box-3 .custom-list li {
    font-size: 12px;
    display: inline-block;
    padding-right: 5px;
    text-transform: uppercase;
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
}

.car-box-3 .custom-list li a{
    color: #5a5959;
}

.car-box-3 .detail .title {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 500;
    text-transform: uppercase;
}

.car-box-3 .detail .title a:hover {
    color: #343a40;
}

.car-box-3 .for {
    position: absolute;
    left: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 6px 15px 4px;
    color: #fff;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    top: 20px;
    font-family: 'Prompt', sans-serif;
}

.car-box-3 .facilities-list li {
    list-style: none;
    float: left;
    margin-right: 20px;
    font-weight: 400;
    line-height: 32px;
    font-size: 14px;
}

.car-box-3 .facilities-list li{
    font-weight: 500;
    font-size: 15px;
}

.car-box-3 .price-box {
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    bottom: 15px;
}

.car-box-3:hover .price-box{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.car-box-3 .for {
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.car-box-3:hover .for{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.car-box-3 .footer {
    border-top: 1px solid #e5e5e5;
    background: #fff;
    font-size: 14px;
}

.car-box-3 .price-box {
    z-index: 999;
}

.car-box-3 .for {
    z-index: 999;
}

.car-box-3 .tag-2 {
    z-index: 999;
}

.car-box-3 .footer p {
    line-height: 48px;
    margin: 0 0 0 20px;
    font-size: 15px;
}

.car-box-3:hover .overlap-box {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.car-box-3 .footer {
    font-size: 15px;
    border-top: solid 1px #efefef;
}

.car-box-3 .ratings{
    line-height: 49px;
    padding-left: 25px;
}

.car-box-3 .ratings i{
    color: orange;
}

.car-box-2 .for {
    position: absolute;
    top: 20px;
    left: 20px;
}

/** Car box 4 start **/
.car-box-4 {
    position: relative;
    height: 385px;
    overflow: hidden;
    background: #fff;
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    width: 100%!important;
}

.car-box-4.car-photo-1{
    background: url(../img/car/car-4.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.car-box-4.car-photo-2{
    background: url(../img/car/car-2.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.car-box-4.car-photo-3{
    background: url(../img/car/car-3.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.car-box-4.car-photo-4{
    background: url(../img/car/car-1.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.car-box-4:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: linear-gradient(to top,rgb(47 45 45 / 99%) 0%,rgb(0 0 0 / 51%) 35%,rgba(2, 2, 2, 0) 60%,rgba(0,0,0,0) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.car-box-4 h3 {
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 12px;
}

.car-box-4 .ling-section {
    position: absolute;
    bottom: 25px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
}

.car-box-4 h3 a{
    color: #fff;
}

.car-box-4 h3 a:hover{
    color: #fff;
    opacity: 0.9;
}

.car-box-4 .read-more-btn {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 87px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.6;
    transition: 0.6;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding: 9.5px 22px;
    border-radius: 3px;
    background: #fff;
}

.car-box-4 .read-more-btn::before {
    content: '';
    position: absolute;
    width: 0;
    -webkit-transition: .5s;
}

.car-box-4 .facilities-list {
    display: inline-block;
    -webkit-transition: .4s;
    transition: .4s;
}

.car-box-4 .facilities-list li {
    list-style: none;
    float: left;
    margin-right: 20px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.car-box-4 .facilities-list li strong{
    color: #fff;
    font-weight: 500;
}

.car-box-4 .facilities-list li:last-child{
    padding-right: 0;
    margin-right: 0;
    border-right: none;
}

.car-box-4:hover .read-more-btn {
    opacity: 1;
    visibility: visible;
    bottom: 0;
    text-align: left;
}

.car-box-4:hover h3 {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.car-box-4:hover .facilities-list {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

/** Featured car 4 start**/
.featured-car-4 .carousel-item {
    margin-bottom: 30px;
}

.featured-car-4 .carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    border-radius: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: #2f2f2f;
}

.featured-car-4 .carousel-indicators {
    bottom: -15px;
}

.featured-car-4 .btn:focus, .btn:active, .btn:focus {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1)!important;
}

.featured-car-4 .btn:focus, .btn:active, .btn:focus {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1)!important;
}

.advanced-search2{
    box-shadow: none;
}

.advanced-search2 .btn:focus, .btn:active, .btn:focus {
    box-shadow: none!important;
}

/** Car box 5 **/
.car-box-5 img{
    height: 550px;
}

.car-box-5 .detail{
    max-width: 350px!important;
    padding: 30px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,0.2);
}

.car-box-5 .detail ul {
    margin-bottom: 20px;
}

.car-box-5 .detail ul li {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
}

.car-box-5 .detail ul li strong {
    font-weight: 500;
    color: #fff;
}

.car-box-5 .detail ul li a{
    color: #fff;
}

.car-box-5 .detail h3 {
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 20px;
}

.car-box-5 .carousel-caption {
    right:0;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 100px 30px;
}

.car-box-5 .btn-3 {
    padding: 0 25px 0 25px;
    line-height: 38px;
}

.car-box-5 .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    opacity: 1;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
}

/** Car box 5 **/
.vs{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 75px;
    color: #fff;
    margin: 240px auto;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.search-compare{
    text-align: center;
}

.search-compare h3{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 24px;
}

/** Our newslatters 2 Start **/
.our-newslatters-2 .inner {
    background: url(../img/img-5.png);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 40px 25px 35px;
    border-radius: 5px;
    margin: 0 0 30px;
}

.our-newslatters-2 .inner:before {
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: #0007eb;
    opacity: 0.3;
    z-index: -1;
}

.our-newslatters-2 .inner .intro-text h3 {
    font-size: 27px;
    margin-bottom: 5px;
    color: #fff;
    line-height: normal;
    font-weight: 500;
}

.our-newslatters-2 .inner p {
    color: #fff;
    opacity: 0.9;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
}

.our-newslatters-2 .form-control {
    width: 80%;
    height: 50px;
    padding: 10px 15px;
    font-size: 15px;
    outline: 0;
    border-radius: 3px;
    border: transparent;
}

.our-newslatters-2 .btn {
    color: #fff;
    padding: 15px 5px 15px 5px;
    font-size: 15px;
    height: 50px;
    top: 0;
    right: 0;
    text-transform: inherit;
    font-weight: 400;
    border-radius: 3px;
    border: none!important;
    width: 20%;
}

.our-newslatters-2 .newsletter-content-wrap{
    margin-top: 7px;
}

/** About text **/
.about-text {
    padding: 35px 0 0 30px;
}

.about-text p {
    margin-bottom: 20px;
}

.about-text h3 {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #000000;
}

.about-slider-box{
    position: relative;
}

.about-slider-box img{
    border-radius: 10px;
}

.about-slider-box:before {
    position: absolute;
    left: -0px;
    top: -0px;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    content: "";
}

.about-slider-box img {
    position: relative;
    margin: 0;
    z-index: 9;
    left: 15px;
    top: 15px;
    border-radius: 5px;
}

/** Pricing 1 start **/
.pricing-1 {
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
    background: #fff;
    -webkit-transition: .9s;
    transition: .9s;
    overflow: hidden;
    border-radius: 9px;
}

.pricing-1:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
}

.pricing-1:hover:before {
    width: 100%;
}

.pricing-1::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    -webkit-transition: .9s;
    transition: .9s;
    border-radius: 9px;
}

.pricing-1:hover .plan-header h5, .pricing-1:hover .plan-price sup, .pricing-1:hover .plan-price, .pricing-1:hover .plan-header p, .pricing-1:hover .plan-price span, .pricing-1:hover .plan-list ul, .pricing-1:hover .plan-list ul li i {
    color: #fff!important;
}

.pricing-1:hover .plan-list ul li {
    border-bottom: 1px dashed #ffffff63;
}

.pricing-1 .plan-header .plan-price span {
    font-size: 16px;
    color: #5a5959;
    font-weight: 500;
}

.pricing-1 .plan-header {
    padding: 40px 22px 0;
    border-width: 1px;
    position: relative;
    text-align: center;
}

.pricing-1 .plan-header h5 {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
}

.pricing-1 .plan-header .plan-price sup {
    font-size: 24px;
    position: relative;
    top: -30px;
    color: #5a5959;
    font-weight: 500;
}

.pricing-1 .plan-header .plan-price {
    font-size: 60px;
    font-weight: 700;
    position: initial;
    text-align: center;
    font-family: 'Prompt', sans-serif;
    padding: 0;
    width: 100%;
}

.pricing-1 .plan-header .plan-price span {
    font-size: 16px;
    color: #5a5959;
    font-weight: 500;
}

.pricing-1 .plan-list {
    border-width: 0 1px 1px;
    padding: 20px 0 40px;
}

.pricing-1 .plan-list ul {
    display: table;
    margin: 0 auto;
    padding: 0 40px;
}

.pricing-1 .plan-list ul li {
    list-style: none;
    border-bottom: 1px dashed #eaeceb;
    line-height: 42px;
    font-size: 15px;
    font-weight: 500;
}

.pricing-1 .plan-list ul li i {
    margin-right: 12px;
}

.pricing-1 .plan-list ul li:last-child {
    border-bottom: none;
}

.pricing-1 .plan-button {
    padding-top: 20px;
}

.pricing-1 .pricing-btn {
    border-radius: 3px;
}

.pricing-1:hover .pricing-btn{
    background-color: #fff;
    border: solid 1px #fff;
}

.pricing-1 .pricing-btn {
    font-size: 15px;
    border-radius: 3px;
    font-weight: 500;
    padding: 7px 30px 8px;
}

/** Team 1 start **/
.team-1{
    text-align: center;
    max-width: 200px;
    margin: 0 auto 30px;
}

.team-1 .team-inner {
    padding: 0 10px;
    margin-bottom: 30px;
}

.team-1 .team-inner img {
    margin:0 auto 20px;
    width: 200px!important;
    height: auto!important;
    border-radius: 50%!important;
    border: 1px solid #eee;
}

.team-1 .team-inner h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
}

.team-1 .team-inner h4 {
    margin-bottom: 7px;
    font-weight: 600;
}

.team-1 .team-inner h4 a {
    font-size: 20px;
    font-weight: 500;
}

.team-1 .team-inner h4 a:hover{
    color: #212121;
}

.team-1 .team-inner p {
    font-size: 16px;
    margin-bottom: 5px;
}

.team-1 .team-inner p a {
    font-size: 15px;
    color: #5a5959;
}

.team-1 h6 a:hover{
    color: #5a5959;
}

.team-1 .social {
    list-style: none;
    padding: 0;
    text-align: center;
    opacity: 0;
    position: absolute;
    bottom: 145px;
    transition: all 0.3s ease 0s;
}

.team-1 .social li {
    display: inline-block;
    text-align: center;
}

.team-1 .social li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color: #fff;
    position: relative;
    transform: translate(17px, -35px);
    transition: all 0.5s ease-in-out 0s;
    border-radius: 30px;
    margin: 2px 0px;
}

.team-1 .social li a:hover {
    transition-delay: 0s;
}

.team-1:hover .social {
    opacity: 1;
    text-align: center;
    bottom: 165px;
}

.team-1:hover .social li:nth-child(1) a {
    transition-delay: 0.3s;
}

.team-1:hover .social li:nth-child(2) a {
    transition-delay: 0.2s;
}

.team-1:hover .social li:nth-child(3) a {
    transition-delay: 0.1s;
}

.team-1:hover .social li a {
    transform: translate(17px, 25px);
}

/** Team 2 start **/
.team-2 {
    margin-bottom: 30px;
    background: #fff!important;
    position: relative;
    z-index: 2;
}

.team-2:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.team-2 img {
    width: 100%;
}

.team-2 .photo {
    overflow: hidden;
    position: relative;
}

.team-2 .detail {
    padding: 1rem;
}

.team-2 .detail h4 {
    margin: 0 0 5px;
    font-size: 24px;
    font-weight: 500;
}

.team-2 .detail h4 a {
    color: #212121;
}

.team-2 .detail h5 {
    margin: 0 0 20px;
    font-size: 12px;
    font-weight: 600;
    color: #5f5e5e;
    text-transform: uppercase;
}

.team-2 .detail .contact ul li {
    line-height: 30px;
    font-size: 15px;
    position: relative;
    padding-left: 22px;
}

.team-2 .detail .contact ul li i {
    left: 0;
    position: absolute;
    line-height: 30px;
}

.team-2 .detail .contact ul li a{
    color: #5a5959;
}

.team-2 .photo::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    background: #fff;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.team-2 .photo img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.team-2 .photo::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    background: #fff;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.team-2 .photo:hover .photo::before, .team-2:hover .photo::after {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    visibility: visible;
    background-image: linear-gradient(100deg, #005bff 8%, #337cea61 82%)!important;
}

.a-three .detail {
    padding: 50px 30px 0;
}

.team-2 .social-list li {
    display: inline-block;
    font-size: 16px;
    z-index: 999;
}

.team-2:hover .social-list{
    z-index: 999!important;
}

.team-2 .social-list {
    position: absolute;
    top: 15px;
    text-align: center;
    z-index: 20;
    border-radius: 5px 5px 0 0;
    left: 15px;
    width: 45px;
    color: rgb(255, 255, 255);
}

.team-2 .social-list li a {
    margin-right: 0px;
    font-size: 17px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
    color: #fff!important;
    margin-bottom: 3px;
}

.col-pad{
    padding: 0;
}

.team-2:hover li a {
    transform: rotateY(360deg);
}

/** Team 3 start **/
.team-3 {
    text-align: center;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -o-transition: .5s all ease;
    -ms-transition: .5s all ease;
    background: #fff;
    margin-bottom: 30px;
}

.team-3:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.team-3 .team-thumb {
    position: relative;
}

.team-3 .team-thumb img {
    width: 100%;
    height: auto;
    border: none;
    display: block;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.team-3 .team-social {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
}

.team-3 .flex-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-3 .team-social .team-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(-110%);
    transition: all 0.5s linear;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -o-transition: .5s all ease;
    -ms-transition: .5s all ease;
}

.team-3 .team-social .team-social-inner {
    position: absolute;
    left: 0;
    opacity: 0;
    padding-left: 20px;
    transform: translateX(-100px);
    transition: .3s all ease 0.4s;
    transition: .3s all ease 0.4s;
    -webkit-transition: .3s all ease 0.4s;
    -moz-transition: .3s all ease 0.4s;
    -o-transition: .3s all ease 0.4s;
    -ms-transition: .3s all ease 0.4s;
}

.team-3 .team-social a {
    display: block;
    font-size: 18px;
    line-height: 1;
    margin: 5px 0;
    padding: 11px;
    background: #fff;
    border-radius: 5%;
    transition: .3s all ease 0.4s;
    transition: .3s all ease 0.4s;
    -webkit-transition: .3s all ease 0.4s;
    -moz-transition: .3s all ease 0.4s;
    -o-transition: .3s all ease 0.4s;
    -ms-transition: .3s all ease 0.4s;
    color: #000;
}

.team-3 .team-social a:hover{
    color: #000;
}

.team-3 .team-info {
    padding: 30px 10px;
}

.team-3 .team-info h4{
    margin-bottom: 7px;
}

.team-3 .team-info h4 a{
    color: #212121;
    font-size: 22px;
    font-weight: 500;
}

.team-3 .team-info p {
    margin-bottom: 5px;
}

.team-3 .team-info p a{
    color: #5a5959;
    font-size: 15px;
}

.team-3 .team-info p a:hover{
    color: #5a5959;
}

.team-3:hover .team-social .team-overlay {
    transform: translateY(0);
    opacity: .5;
}

.team-3 .team-social .team-overlay, .team-sub-line:before {
    background-image: linear-gradient(100deg, #005bff 8%, #337cea61 82%)!important;
}

.team-3:hover .team-social .team-social-inner {
    transform: translateX(0);
    opacity: 1;
}

/** Banner 2 start **/
.banner-2 {
    font-family: 'Muli', sans-serif;
    overflow: hidden;
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-2 .slide {
    height: 100vh;
    border-radius: 0;
    margin: 0;
    cursor: pointer;
    color: #fff;
    text-align: center;
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    transition: all 500ms ease-in-out;
}

.banner-2 .breadcrumb-area {
    position: absolute;
    top: 45%;
    right: 0;
    left: 0;
}

.banner-2 .slide h2 {
    opacity: 0;
    color: #fff;
    margin-bottom: 15px;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner-2 .slide .breadcrumb-area{
    position: absolute;
    top: 45%;
    right: 0;
    padding: 0 15px;
    left: 0;
}

.banner-2 .slide p{
    opacity: 0;
}

.banner-2 .slide .btn-3{
    opacity: 0;
}

.banner-2 .slide.active {
    flex: 10;
}

.banner-2 .slide.active h2 {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
}

.banner-2 .slide.active p {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
}

.banner-2 .slide.active .btn-3 {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
}

.banner-2 p {
    color: #fff;
    line-height: 1.6;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/** Banner 3 start**/
.banner-3 .slider-container {
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.banner-3 .left-slide {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transition: transform .5s ease-in-out;
}

.banner-3 .right-slide {
    height: 100%;
    position: absolute;
    top: 0;
    width: 50%;
    transition: transform .5s ease-in-out;
}

.banner-3 .left-slide > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.banner-3 .left-side h1 {
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: -30px;
}

.banner-3 .right-slide div {
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
}

.banner-3 .b2i-2{
    display: none;
}

.banner-3 .right-slide > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-3 button {
    background-color: #fff;
    border: none;
    color: #fff;
    cursor: pointer;
    border: 0;
    font-size: 15px;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.banner-3 button:hover {
    color: #fff;
}

.banner-3 .slider-container .action-buttons button {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100;
}

.banner-3 .slider-container .action-buttons .down-button {
    transform: translateY(-100%);
    border: 0;
}

.banner-3 .slider-container .action-buttons .up-button {
    transform: translateX(-100%);
    border: 0;
}

.banner-3 .banner-info-3{
    text-align: center;
    max-width: 550px;
    margin: 0 auto;
    padding: 0 30px;
}

.banner-3 .banner-info-3 p{
    margin-bottom: 20px;
    font-size: 17px;
}

.banner-3 .banner-info-3 h1{
    font-size: 55px;
    font-weight: 600;
    margin-bottom: 10px;
}

.banner-3 .fa-arrow-down:before {
    content: "\f063";
}

/* Animated text 1 area start*/
.banner-3 .animated-text-1 .animate-charcter {
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}






/* Animated text 2 area start*/
.animated-text-2 h1 {
    position: absolute;
    font-family: 'Prompt', sans-serif;
    text-transform: uppercase;
    font-size: 90px;
    text-align: center;
}

.animated-text-2 h1:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #212121;
}

.animated-text-2 h1:nth-child(2) {
    color: #212121;
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
    0%,
    100% {
        clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%
        );
    }

    50% {
        clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%
        );
    }
}

/** pages 404 start **/
.pages-404 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    text-align: center;
}

.pages-404 .pages-404-inner h1 {
    color: #000;
    font-size: 200px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: normal;
}

.pages-404 .pages-404-inner h5 {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 20px;
}

.pages-404 .pages-404-inner p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
}

.pages-404 .p-404-i-inner{
    max-width: 450px;
    margin: 0 auto;
}

/** Latest offers section start**/
.latest-offers-box {
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
}

.latest-offers-box .latest-offers-box-photo {
    position: relative;
    transform: scale(1);
    transition: 1s;
}

.latest-offers-box .latest-offers-box-inner:hover .latest-offers-box-photo {
    transform: scale(1.2);
}

.latest-offers-box .latest-offers-box-overflow {
    overflow: hidden;
    position: relative;
    border-radius: 3px;
}

.latest-offers-box-photo:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.latest-offers-box-photo:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top,rgba(47, 45, 45, 0.4) 0%,rgba(0, 0, 0, 0.45) 35%,rgba(2, 2, 2, 0) 60%,rgba(0,0,0,0) 100%);
    bottom: 0;
}

.latest-offers-box .latest-offers-box-inner:hover .latest-offers-box-photo:before {
    width: 100%;
}

.latest-offers-box .latest-offers-box-photo:before {
    position: absolute;
    content: '';
    background: rgb(12 62 185 / 51%);
    right: 0;
    width: 0%;
    height: 100%;
    transition: 1s;
}

.latest-offers-box .info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 20;
    color: #fff;
    font-weight: 500;
}

.latest-offers-box .info h3{
    margin-bottom: 0;
    font-weight: 500;
}

.latest-offers-box .info h3 a{
    color: #fff;
    font-size: 22px;
}

.latest-offers-box .info p{
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
}

.latest-offers-box .new {
    position: absolute;
    z-index: 20;
    text-align: center;
    display: block;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 1px rgb(255 255 255 / 20%);
    box-shadow: 0px 0px 0px 1px rgb(255 255 255 / 20%);
    border-radius: 3px;
    font-weight: 500;
    top: 20px;
    right: 20px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
}

.latest-offers-box:hover .new {
    transform: rotateY(360deg);
}

.latest-offers-box img{
    width: 100%;
    /* height: 250px; */
}

.latest-offers-box .big-img{
    /* height: 515px; */
    width: 100%;
}

/** Social media area start **/
.social-media .social-list{
    display: inline-flex;
}

.social-media .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 3px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-media .icon span{
    color: #fff;
}

.social-media .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-media .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-media .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.social-media .icon:hover span,
.social-media .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.social-media .facebook:hover,
.social-media .facebook:hover .tooltip,
.social-media .facebook:hover .tooltip::before {
    background-color: #3b5999;
    color: #ffffff!important;
}

.social-media .twitter:hover,
.social-media .twitter:hover .tooltip,
.social-media .twitter:hover .tooltip::before {
    background-color: #46c1f6;
    color: #ffffff!important;
}

.social-media .instagram:hover,
.social-media .instagram:hover .tooltip,
.social-media .instagram:hover .tooltip::before {
    background-color: #e1306c;
    color: #ffffff;
}

.social-media .github:hover,
.social-media .github:hover .tooltip,
.social-media .github:hover .tooltip::before {
    background-color: #333333;
    color: #ffffff;
}

.social-media .youtube:hover,
.social-media .youtube:hover .tooltip,
.social-media .youtube:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
}

.social-media-two .facebook{
    background-color: #3b5999;
}

.social-media .twitter{
    background-color: #46c1f6;
}

.social-media .instagram{
    background-color: #e1306c;
}

.social-media .github{
    background-color: #333333;
}

.social-media .youtube{
    background-color: #de463b;
}

/** Blog 1 start **/
.blog-1 {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-1 .blog-photo{
    position: relative;
}

.blog-1:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.blog-1 .blog-image {
    overflow: hidden;
    position: relative;
}

.blog-1 .blog-image::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.blog-1 .blog-image img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 999;
}

.blog-1 .blog-image::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.blog-1:hover .blog-image::before, .blog-1:hover .blog-image::after {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    visibility: visible;
    background: rgb(12 62 185 / 51%);
}

.blog-1 .date-box {
    position: absolute;
    top: 15px;
    font-size: 13px;
    color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    right: 19px;
    border-radius: 5px 5px 0 5px;
    z-index: 999;
}

.blog-1 .date-box::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 10px solid transparent;
    bottom: -10px;
    right: 0;
}

.blog-1 .profile-user {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: block;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    z-index: 999!important;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-1:hover .profile-user {
    transform: rotateY(360deg);
}

.blog-1 .profile-user img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.blog-1 .detail {
    padding: 30px 25px 15px;
}

.blog-1 .post-meta {
    width: 100%;
    margin-bottom: 10px;
}

.blog-1 .post-meta ul li {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}

.blog-1 .post-meta ul li strong{
    font-weight: 500;
}

.blog-1 .post-meta ul li strong a{
    color: #5a5959;
}

.blog-1 .post-meta ul li a{
    color: #5a5959;
}

.blog-1 .post-meta ul li i {
    margin-right: 5px;
    color: #5a5959;
}

.blog-1 .post-meta ul li.float-right{
    float: right;
    margin-left: 15px;
}

.blog-1 .detail h4 a:hover{
    color: #212121;
}

.blog-1 h4{
    margin-bottom: 15px;
}

.blog-1 h4 a{
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
}

.blog-1 h4 a:hover{
    color: #212121;
}

.blog-1:hover .date-box{
    background: #fff;
}

.blog-1:hover .date-box::after {
    border-right: 10px solid #fff;
}

.blog-1 .date-box {
    position: absolute;
    top: 15px;
    font-size: 13px;
    color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    right: 19px;
    border-radius: 5px 5px 0 5px;
    z-index: 999;
}

.blog-1 .date-box span {
    font-family: 'Raleway',sans-serif;
    font-size: 19px;
    display: block;
    line-height: 22px;
    color: #fff;
}

/** Blog big **/
.blog-big h3{
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 500;
}

.blog-big{
    box-shadow: none!important;
}

.blog-big .detail {
    padding: 30px 25px;
}

.blog-1:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

/** Blog 2 **/
.blog-2 {
    margin: 25px 0 30px;
    position: relative;
    padding: 60px 30px 10px;
    height: 350px;
}

.blog-2:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top,rgba(35, 32, 32, 0.9) 0%,rgba(0, 0, 0, 0.45) 35%,rgba(2, 2, 2, 0.4) 60%,rgba(0, 0, 0, 0.29) 100%);
    border-radius: 5px;
}

.blog-2:hover{
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.blog-2 .profile-user {
    position: absolute;
    top: -25px;
    z-index: 20;
}

.blog-2 .profile-user img {
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, .4);
    width: 65px!important;
    height: 65px;
    margin-bottom: 20px;
    webkit-border-radius: 50% / 14%;
    border-radius: 50% / 14%;
}

.blog-2 h6 {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    opacity: 0.9;
    font-family: 'Prompt', sans-serif;
}

.blog-2:hover h3 a {
    color: #fff;
    opacity: 1;
}

.blog-2 p {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    opacity: 0.9;
}

.blog-2:hover .b-btn {
    color: #fff;
}

.blog-2:hover p {
    color: #fff;
    opacity: 1;
}

.blog-2:hover h5{
    opacity: 1;
}
.blog-2 h3 {
    margin-bottom: 15px;
}

.blog-2 h3 a {
    font-size: 25px;
    font-weight: 500;
    color: #fff;
}

.blog-2 h3 a:hover{
    color: #fff;
    opacity: 0.9;
}

.blog-2 .b-btn {
    font-weight: 500;
}

.blog-2 .b-btn:hover{
    color: #fff;
    opacity: 0.9;
}

.blog-2 .info{
    position: absolute;
    bottom: 10px;
    right: 30px;
    left: 30px;
    z-index: 20;
}

.blog-bg-1{
    background: rgba(0, 0, 0, 0.04) url(../img/blog/blog-1.png) top left repeat;
    background-size: cover;
    width: 100%;
    border-radius: 5px;
    opacity: 1;
}

.blog-bg-2{
    background: rgba(0, 0, 0, 0.04) url(../img/blog/blog-2.png) top left repeat;
    background-size: cover;
    width: 100%;
    border-radius: 5px;
    opacity: 1;
}

.blog-bg-3{
    background: rgba(0, 0, 0, 0.04) url(../img/blog/blog-3.png) top left repeat;
    background-size: cover;
    width: 100%;
    border-radius: 5px;
    opacity: 1;
}

/** Blog 3 start **/
.blog-3 {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-3 .blog-photo{
    position: relative;
}

.blog-3:hover{
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.blog-3 .blog-image {
    overflow: hidden;
    position: relative;
}

.blog-3 .date-box-2 {
    position: absolute;
    top: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 20px 15px 25px;
    text-align: center;
    right: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    border-radius: 0;
    z-index: 999!important;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-3:hover .date-box-2{
    transform: rotateY(360deg);
    color: #fff;
}

.blog-3 .db-2 {
    clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
}

.blog-3 .post-meta {
    position: absolute;
    bottom: 15px;
    font-size: 15px;
    border-radius: 5px;
    width: 90%;
    margin: 0 5%;
    padding: 7px 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.36);
    z-index: 999;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.blog-3:hover .post-meta a {
    color: #fff;
}

.blog-3 .post-meta span {
    margin: 0 15px 0 0;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 26px;
}

.blog-3 .post-meta span i {
    font-size: 14px;
    margin-right: 5px;
    font-weight: 600;
}

.blog-3 .blog-image::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.blog-3 .blog-image img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 999;
}

.blog-3 .blog-image::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.blog-3:hover .blog-image::before, .blog-3:hover .blog-image::after {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    visibility: visible;
    background: rgb(12 62 185 / 51%);
}

.blog-3 .detail {
    padding: 25px 25px;
}

.blog-3 h3{
    margin-bottom: 12px;
}

.blog-3 h3 a{
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.5;
}

.blog-3 .detail h3 a {
    color: #212121;
}

.blog-3 h3 a:hover{
    color: #444243;
}

.blog-3 .b-btn:hover {
    color: #5a5959;
}

/**  Product slider box start **/
.product-slider-box .product-img-slide {
    display: inline-block;
    width: 100%;
}

.product-slider-box .slider-for {
    width: 80%;
    float: left;
    padding: 0;
    background: transparent;
    border: 1px transparent;
    overflow: hidden;
    border-radius: 0;
}

.product-slider-box .slider-nav {
    width: 20%;
    float: left;
    padding-left: 13px;
    margin-bottom: 0;
}

.product-slider-box .slider-for img{
    border-radius: 3px;
}

.product-slider-box .thumb-slide {
    padding: 0;
    margin-bottom: 5px;
    background: #fff;
    overflow: hidden;
    width: 100%!important;
    border-radius: 3px;
}

.product-slider-box .slick-current {
    border-color: transparent;
}

.product-slider-box .slick-arrow {
    display: none!important;
}

.product-slider-box .slick-next {
    top: auto;
    bottom: 0;
    transform: translateX(-50%) rotate(-90deg);
}

.product-slider-box .slick-slide img {
    margin: 0 auto;
    cursor: pointer;
}

/** Intro section start **/
.intro-section {
    padding: 30px 0 25px;
}

.intro-text h3 {
    font-size: 30px;
    margin: 0;
    line-height: 50px;
    color: #fff;
    font-weight: 400;
}

.intro-section .btn {
    background: #fff;
    float: right;
    color: #37404d;
    padding: 17px 30px 11px 30px;
    height: 50px;
}

.intro-section .btn:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.10), 0 3px 3px 0 rgba(0, 0, 0, 0.20);
}

/** Notice start **/
.notice {
    padding: 18px 15px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border-radius: 2px;
    border: none;
    border-left: 4px solid #838992;
    box-shadow: none;
}

.notice-success {
    border-left-color: #74ba28
}

.notice-success strong {
    color: #74ba28!important;
}

.notice-danger {
    border-left-color: #eb344f;
}

.notice-danger strong {
    color: #eb344f;
}

.notice-info strong {
    color: #1db4bd;
}

.notice-info {
    border-left-color: #1db4bd;
}

.notice-warning {
    border-left-color: #fea911;
}

.notice-warning strong {
    color: #fea911;
}

.notice strong{
    font-weight: 600;
}

.tab-box .nav-pills .nav-link {
    padding: 7px 30px;
}

.tab-box .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    border-radius: 100px;
}

.table-bordered .ass th{
    font-weight: 600;
}

.table-bordered {
    font-weight: 500;
    color: #5a5959;
    background: #fff;
    font-size: 15px;
}

/** Footer start **/
.footer {
    background: url(../img/img-7.png);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.footer .container{
    z-index: 999!important;
    color: #fff;
}

.footer ul li a{
    color: #cecece;
}

.footer h4 {
    font-size: 24px;
    margin-bottom: 7px;
    font-weight: 500;
    color: #e8e7e7;
}

.footer .footer-item {
    margin-bottom: 60px;
}

.footer .footer-inner .links li {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
    padding-left: 20px;
    position: relative;
    line-height: 20px;
}

.footer .footer-inner .links li a i {
    left: 0;
    position: absolute;
    top: 0;
    line-height: 22px;
}

.footer .footer-inner .links li:last-child{
    margin-bottom: 0;
}

.footer .footer-inner .links li a:hover {
    color: #a7a8ab;
}

.footer a{
    color: #cecece;
}

.footer .footer-inner p {
    font-size: 15px;
    color: white !important;
    z-index: 999;
}

.footer .footer-inner p a{
    color: #cecece;
}

.footer .footer-inner .contact-info li {
    color: #cecece;
    font-size: 15px;
    line-height: 45px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 55px;
}

.footer .footer-inner .contact-info li i{
    font-size: 16px;
    float: left;
    left: 0;
    position: absolute;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #1b1e83;
    border-radius: 50px;
}

.footer .social-media{
    float: right;
}

.footer .social-media .icon {
    margin: 0 0 3px 4px;
}

.footer .social-media .icon {
    background: #1b1e83;
    color: #fff!important;
}

.footer .f-logo{
    margin-bottom: 0px;
    height: 50px;
}

.footer-gallery{
    max-width: 255px;
    margin-left: auto;
}

.footer-inner{
    padding: 80px 0 20px;
}

.sub-footer{
    padding: 25px 0 22px;
    border-top: solid 1px #ffffff2e;
    text-align: center;
    margin-bottom: 0;
    color: #fff;
}

.sub-footer p{
    margin-bottom: 0;
    color: #cecece;
    text-align: left;
    line-height: 44px;
}

.footer .form-inline{
    width: 100%;
    border-radius: 100px;
    background: #fff;
}

.footer .subscribe-newsletter .form-control {
    width: 75%;
    height: 60px;
    padding: 10px 30px;
    font-size: 17px;
    outline: 0;
    border-radius: 3px;
    border: transparent;
    margin-right: 3%;
}

.footer .subscribe-newsletter .btn {
    border: none;
    color: #fff;
    padding: 15px 5px 15px 5px;
    height: 60px;
    font-size: 15px;
    top: 0;
    right: 0;
    text-transform: inherit;
    font-weight: 400;
    border-radius: 3px;
    width: 22%;
}

.footer .subscribe-newsletter{
    padding: 40px 0;
    border-bottom: solid 1px #ffffff2e;
}

.footer .subscribe-newsletter .newsletter-content-wrap{
    max-width: 550px;
    margin-left: auto;
}

.footer .subscribe-newsletter h3{
    color: #fff;
    margin-bottom: 0;
    line-height: 60px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
}

.footer .recent-posts .media h5 a{
    color: #919398;
}

.footer .footer-inner .text{
    margin-right: 30px;
}

.footer .s-border {
    background: #ffffff2e;
}

.footer .m-border {
    background: #ffffff2e;
}

.footer .footer-gallery ul li{
    float: left;
    width: 75px;
    height: 75px;
    margin:0 10px 10px 0;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.footer .footer-gallery ul li img {
    width: 100%;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 5px;
    cursor: pointer;
}

.footer .footer-gallery ul li:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 5px;
}

/** Main footer 2 area start **/
.main-footer-2 {
    position: relative;
    background-color: #19181a;
    text-align: center;
    padding-top: 50px;
}

.main-footer-2:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
   /* background: url(../img/footer.png) top left repeat;*/
    opacity: .05;
    background-size: cover;
    background-position: 50%;
}

.main-footer-2 .footer-menu ul {
    margin-bottom: 40px;
}

.main-footer-2 .footer-menu ul li, .footer-menu ul li a {
    display: inline-block;
    position: relative;
}

.main-footer-2 .footer-menu ul li a {
    color: #fff;
    margin-right: 40px;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.main-footer-2 .footer-menu ul li a:before {
    position: absolute;
    top: 3px;
    right: -20px;
    width: 1px;
    height: 15px;
    background-color: #fff;
    content: "";
    opacity: .4;
}

.main-footer-2 .footer-menu ul li:last-child a:before {
    opacity: 0;
}

.main-footer-2 .social-media .social-list{
    margin-bottom: 50px;
}

.main-footer-2 .logo-image{
    margin-bottom: 35px;
}

.main-footer-2 .copy-right p{
    text-align: center;
    color: #fff;
    opacity: 0.9;
    margin-bottom: 0;
}

.main-footer-2 .copy-right p a{
    color: #fff;
    opacity: 0.9;
}

.main-footer-2 img{
    height: 50px;
}

.main-footer-2 .copy-right {
    border-top: solid 1px #a3a3a340;
    padding: 25px 0;
    text-align: center;
    font-size: 15px;
}

/** Lines area start **/
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}

.line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.line::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    z-index: -888;
}

.line:nth-child(1) {
    margin-left: -30%;
}

.line:nth-child(1)::after {
    animation-delay: 1s;
}

.line:nth-child(3) {
    margin-left: 30%;
}

.line:nth-child(3)::after {
    animation-delay: 2s;
}

.line:nth-child(4) {
    margin-left: -15%;
}

.line:nth-child(4)::after {
    animation-delay: 3s;
}

.line:nth-child(5) {
    margin-left: 15%;
}

.line:nth-child(5)::after {
    animation-delay: 4s;
}

@keyframes drop {
    0% {top: -50%; }
    100% {top: 110%; }
}

/** Brand box start **/
.brand-box {
    text-align: center;
    padding: 20px 10px;
    background: #fff;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    color: #505050;
}

.brand-box img {
    height: 50px;
}

.brand-box h5 {
    margin: 25px 0 10px;
    font-size: 18px;
    font-weight: 500;
}

.brand-box h6 {
    margin: 0;
    font-size: 14px;
    font-family: 'Prompt', sans-serif;
}

.view-all ul {
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
}

.view-all ul li {
    display: inline-block;
}

.view-all ul li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin: 0 2px 5px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    background: #fff;
    text-decoration: none;
}

.view-all h3 {
    margin: 0 0 30px;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
}

.view-form-search {
    max-width: 400px;
    margin: 0 auto 50px;
}

.view-form-search .form-search .form-control {
    border: 1px solid #fff;
    border-radius: 3px;
    background: #fff;
    height: 50px;
    padding: 10px 30px;
    font-size: 16px;
}

.view-form-search .form-search .btn {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 13px 20px 11px 13px;
}

/** Posts by category start **/
.posts-by-category ul {
    margin: 0;
    padding: 0;
    text-align: left;
}

.posts-by-category ul li {
    margin-bottom: 10px;
}

.posts-by-category ul li a {
    color: #5a5959;
    font-weight: 500;
    font-size: 15px;
}

.posts-by-category ul li:last-child {
    border-bottom: none;
    margin: 0;
}

/** Tags box start **/
.tags-box ul li {
    display: inline-block;
}

.tags-box ul li a {
    border: 1px solid #f3f3f3;
    background: #f3f3f3;
    color: #5a5959;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 5px;
    padding: 5px 13px;
    font-weight: 400;
    text-transform: capitalize;
    border-radius: 3px;
}

.tags-box ul li a:hover {
    color: #fff;
}

/** Recent posts box start **/
.recent-posts-box{
    position: relative;
}

.recent-posts-box img {
    width: 70px;
    border-radius: 3px;
}

.recent-posts-box h5 {
    margin-bottom: 7px;
    font-weight: 500;
}

.recent-posts-box h5 a {
    font-size: 19px;
    color: #212121;
}

.recent-posts-box h5 a:hover{
    color: #2d2d2d;
}

.recent-posts-box .listing-post-meta {
    font-size: 14px;
    color: #5a5959;
}

.recent-posts-box .listing-post-meta a{
    color: #5a5959;
}

/** Question start **/
.question .contact-info li {
    line-height: 25px;
    padding-left: 22px;
    font-weight: 500;
    position: relative;
    font-size: 15px;
    margin-bottom: 15px;
    color: #5a5959;
}

.question .contact-info li i {
    left: 0;
    position: absolute;
    top: 0;
    font-size: 15px;
}

.question .contact-info li a{
    color: #5a5959;
}

.question .contact-info li a:hover{
    color: #5a5959;
}

.question .social-list li {
    text-align: center;
    display: inline-block;
    list-style: none;
}

.question .social-list li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    color: #fff;
    margin: 2px 0;
}

/** Counters 1 start **/
.counters-1{
    background: url(../img/img-5.png);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.counter-box-1{
    position: relative;
    z-index: 2;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    padding: 80px 0;
    text-align: center;
}

.counter-box-1:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.counter-box-1 h1 {
    font-weight: 600;
    color: #fff;
    margin: 10px 0 15px;
    font-size: 35px;
}

.counter-box-1 i {
    font-size: 55px;
    line-height: inherit;
    display: inline-block;
    vertical-align: middle;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-1:hover i {
    transform: rotateY(360deg);
    color: #fff;
}

.counter-box-1 h5{
    margin: 0;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
}

.counter-box-1:hover h5 span{
    color: #fff;
}

/** Counters 2 start **/
.counters-2 {
    padding: 80px 0 50px;
    background: url(../img/banner/img-5.png);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.counter-box-2{
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-bottom: 30px;
}

.counter-box-2 .icon {
    position: absolute;
    font-size: 40px;
    width: 65px;
    border-radius: 2px;
    margin-right: 20px;
    background: #fff;
    height: 65px;
    text-align: center;
    line-height: 65px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-2:hover .icon {
    transform: rotateY(360deg);
}

.counter-box-2 .detail {
    margin-left: 87px;
    text-align: left;
}

.counter-box-2 h2 {
    font-weight: 700;
    color: #fff;
    margin: 0 0 5px;
    font-size: 30px;
}

.counter-box-2 h5 {
    margin: 0;
    line-height: normal;
    font-size: 19px;
    color: #fff;
    font-weight: 400;
}

/** Counters 3 start **/
.counters-3{
    background: #f3f3f3;
}

.counters-3 .border-r{
    border-right: solid 1px #0000001a;
}

.counters-3 .border-l{
    border-left: solid 1px #0000001a;
}

.counter-box-3{
    position: relative;
    z-index: 2;
    -webkit-transition: .5s;
    overflow: hidden;
    padding: 80px 0;
    text-align: center;
}


.counter-box-3 h1 {
    margin: 20px 0 10px;
    font-weight: 600;
}

.counter-box-3 .icon {
    font-size: 45px;
    line-height: 100px;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: #fff;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    transition: 0.9s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.counter-box-3:hover .icon i{
    color: #fff;
}

.counter-box-3:hover .icon {
    transform: rotateY(360deg);
}

.counter-box-3 h5{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

/** Cardetailsslider 2 start **/
.cardetailsslider-2 .main {
    width:100%;
    display:block;
    margin:0 auto;
}

.cardetailsslider-2 .slick-dots{
    display: none!important;
}

.cardetailsslider-2 button{
    display: none!important;
}

.cardetailsslider-2 .slider-slider-for-photo{
    margin-bottom: 0;
}

.cardetailsslider-2 .slick-slide img {
    display: initial;
    cursor: pointer;
}

/** Service info 2 start **/
.service-info-2 {
    padding: 30px 20px;
    margin-bottom: 30px;
    background: #fff;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    position: relative;
    border-radius: 4px;
    z-index: 1;
}

.service-info-2:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mt-50{
    margin-top: 50px;
}

.service-info-2:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.service-info-2 .read-more {
    font-weight: 500;
    font-size: 16px;
}

.service-info-2 h3 {
    margin-bottom: 15px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 22px;
    font-weight: 500;
}

.service-info-2 p {
    margin-bottom: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.service-info-2 .icon {
    margin: 0 auto 25px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    line-height: 100px;
    top: 0;
    font-size: 40px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.service-info-2:hover .icon {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.service-info-2::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: -1;
}

.service-info-2:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.service-info-2:hover::before {
    height: 100%;
}

.service-info-2:hover .icon{
    border: solid 1px #fff;
    background: #fff;
}

.service-info-2:hover p {
    color: #fff;
}

.service-info-2:hover h3 a{
    color: #fff;
}

.service-info-2:hover .read-more {
    color: #ffffff;
}

/** Service section start **/
.service-section-3{
    background: url(../img/img-2.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 1;
    position: relative;
}

.service-info-3 {
    margin: 0 0 30px 0;
    width: 100%;
    background: #fff;
    padding: 70px 20px 10px;
    position: relative;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    text-align: left;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.service-info-3:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.service-section-3:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
}

.service-info-3 .icon {
    margin-bottom: 20px;
    top: 0;
    line-height: normal;
    font-size: 50px;
}

.service-info-3 .number {
    font-size: 170px;
    line-height: 1;
    color: #f6f6f6;
    color: rgba(255,255,255,0.2);
    display: inline-block;
    position: absolute;
    z-index: 0;
    right: 15px;
    font-weight: 600;
    top: 25px;
    margin-top: -25px;
    font-family: "Poppins", sans-serif;

    transition: all 600ms ease;
    display: inline-block;
}

.service-info-3:hover .number{
    -webkit-transform: scale(-1) rotate(180deg);
    -moz-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    -o-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}

.service-info-3 h5{
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    opacity: 0.9;
}

.service-info-3 h5 a{
    color: #fff;
}

.service-info-3 p{
    font-size: 15px;
    color: #fff;
    opacity: 0.8;
}

/** Advantages 3 start **/
.service-section-2 {
    background: rgba(0, 0, 0, 0.04) url(../img/bg-img-6.png) top left repeat;
    background-size: cover;
    width: 100%;
    padding: 100px 0 70px;
}

.service-section-2 .main-title{
    text-align: left;
    margin-bottom: 0;
}

.service-section-2 .main-title p{
    margin-bottom: 20px;
}

.featured-car .filtr-item {
    padding: 0 15px;
}

/** Testimonial start **/
.testimonial-2 {
    opacity: 1;
    background: rgba(0, 0, 0, 0.04) url(../img/img-4.png) top left repeat;
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.testimonial-2 .testimonial-info-box {
    margin: 50px 0 0;
    border: none;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 80px 30px 30px;
    text-align: center;
    background: #fff;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
    border-radius: 5px;
    z-index: 1;
}

.testimonial-2 .testimonial-info-box:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-2 .testimonial-info-box .profile-user {
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
}

.testimonial-2 .testimonial-info-box .avatar{
    max-width: 100px!important;
    margin: 0 auto;
}

.testimonial-2 .testimonial-info-box .avatar img{
    width: 100%;
    border-radius: 100px;
}

.testimonial-2 .testimonial-info-box h5 {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 20px;
}

.testimonial-2 .testimonial-info-box p{
    margin-bottom: 0;
}

.testimonial-2 .testimonial-info-box h6 {
    margin-bottom: 15px;
    font-size: 15px;
    font-family: 'Prompt', sans-serif;
}

.testimonial-2 .testimonial-info-box::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
    z-index: -1;
}

.testimonial-2 .testimonial-info-box i {
    display: inline-block;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
}

.testimonial-2 .testimonial-info-box:hover i {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.testimonial-2 .testimonial-info-box:hover::before {
    height: 100%;
}

.testimonial-2 .testimonial-info-box:hover p {
    color: #fff;
}

.testimonial-2 .testimonial-info-box:hover h5 a {
    color: #fff;
}

.testimonial-2 .testimonial-info-box:hover h6{
    color: #fff;
}

.testimonial-2 .main-title {
    margin-bottom: 0;
    text-align: left;
}

.testimonial-2 h1 {
    color: #fff;
}

.testimonial-2 .main-title p {
    color: #fff;
    opacity: 0.9;
    margin-bottom: 20px;
    font-size: 17px;
}

/** Testimonial 3 start **/
.testimonial-3 {
    opacity: 1;
    background: #f3f3f3;
}

.testimonial-3 {
    background: url(../img/img-1.png);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    opacity: 1;
}

.testimonial-3 .main-title h1 {
    color: #fff;
}

.testimonial-3 .main-title p {
    color: #fff;
    opacity: 0.9;
}

.testimonial-3 .testimonial-item-new {
    position: relative;
    z-index: 1;
    padding: 0px 70px 42px 60px;
    max-width: 540px;
    margin: 0 auto 30px;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
}

.testimonial-3 .testimonial-item-new::before {
    content: '';
    position: absolute;
    clip-path: polygon(0 14%, 100% 0, 100% 100%, 0% 100%);
    background: white;
    width: 100%;
    height: 316px;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.testimonial-3 .testimonial-item-new .pl-40{
    padding-left: 40px;
}

.testimonial-3 .testimonial-item-new .author-img {
    padding-bottom: 20px;
}

.testimonial-3 .testimonial-item-new .author-img .author-avatar{
    position: relative;
    float: right;
}

.testimonial-3 .testimonial-item-new .fix{
    overflow: hidden;
}

.testimonial-3 .testimonial-item-new .author-img img {
    border-radius: 50%;
    height: 120px;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
}

.testimonial-3 .testimonial-item-new:hover img{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.testimonial-3 .testimonial-item-new .author-img .icon{
    position: absolute;
    top: 80px;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-3 .testimonial-item-new .rating i {
    display: inline-block;
    -webkit-transition: all 0.9s;
    transition: all 0.9s;
    color: orange;
}

.testimonial-3 .testimonial-item-new:hover .author-content h5 .desig {
    color: red;
}

.testimonial-3 .testimonial-item-new:hover .left-line::before {
    background: red;
}

.testimonial-3 .testimonial-item-new:hover .author-img .icon {
    background: red;
}

.testimonial-3 .testimonial-item-new:hover .rating i {
    color: red;
}

.testimonial-3 .testimonial-item-new:hover i {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.testimonial-3 .testimonial-item-new .author-content{
    margin-bottom: 15px;
}

.testimonial-3 .testimonial-item-new .author-content h5.left-line {
    position: relative;
}

.testimonial-3 .testimonial-item-new .left-line:before {
    width: 25px;
    height: 2px;
}

.testimonial-3 .testimonial-item-new .left-line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    width: 25px;
    height: 2px;
    z-index: 1;
}

.testimonial-3 .testimonial-item-new .author-content h5 .desig {
    font-size: 16px;
    font-weight: 500;
}

.testimonial-3 .testimonial-item-new .author-content h5{
    letter-spacing: 0;
    font-size: 22px;
    font-weight: 500;
}

/** Testimonial 4 start **/
.testimonial-4{
    background: #f3f3f3;
}

.testimonial-4 .testimonial-info {
    border: 0;
    padding: 35px 30px 35px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.testimonial-4 .testimonial-info .text p {
    margin: 20px 0 0;
    font-size: 16px;
}

.testimonial-4 .testimonial-info .user-section {
    display: flex;
    align-items: center;
    position: relative;
}

.testimonial-4 .testimonial-info .user-section:before {
    content: "";
    position: absolute;
    left: -31px;
    top: 0;
    border-radius: 6px;
    width: 6px;
    height: 100%;
}

.testimonial-4 .testimonial-info .user-thumb {
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
}

.testimonial-4 .testimonial-info .user-thumb img {
    transition: 0.3s;
    border-radius: 50%;
    height: 90px;
}

.testimonial-4 .testimonial-info .icon {
    position: absolute;
    top: 50px;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-4 .testimonial-info .user-name h5 {
    margin-bottom: 0;
    font-weight: 500;
}

.testimonial-4 .testimonial-info .user-name p{
    margin-bottom: 0;
}

/** Listings parallax start **/
.listings-parallax {
  /*  background: url(../img/listings-parallax.png);*/
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #efefef;
    padding-top: 80px;
}

.listings-parallax .text {
    display: inline-block;
}

/** Social links start SOCIAL **/
.social-links .social-list li {
    display: inline-block;
    font-size: 16px;
}

.social-links .social-list li a {
    margin-right: 2px;
    font-size: 14px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}

/** Option bar start **/
.option-bar {
    margin-bottom: 30px;
}

.option-bar .sort {
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    line-height: 35px;
}

.sorting-options2{
    float: left;
}

.sorting-options2 h5{
    line-height: 50px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}

.sorting-options span {
    line-height: 35px;
}

.sorting-options{
    float: right;
}

.sorting-options-3{
    width: 200px;
    margin-right: 3px;
}

.change-view-btn {
    border-radius: 2px;
    border: solid 2px #e4e4e4;
    margin: 0 2px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: block;
    float: left;
    font-size: 18px;
}

.change-view-btn:hover{
    background: #e4e4e4;
    border: solid 1px #e4e4e4;
}

.active-view-btn {
    background: #e4e4e4;
    border: solid 1px #e4e4e4;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: block;
    float: left;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ccc !important;
    filter: "alpha(opacity=20)";
    -ms-filter: "alpha(opacity=20)";
}

.option-bar .btn-light:hover {
    color: #636363;
}

/**  Comments start **/
.comments {
    list-style: none;
    margin: 0;
    padding: 0;
}

.comment {
    margin: 0 0 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;
}

.comment h5{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

.comment .title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.comment-author {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    left: 0;
    position: absolute;
    top: 20px;
}

.comment-author a img {
    bottom: 2px;
    border-radius: 50%;
    display: block;
    width: 100%;
    position: absolute;
    top: 2px;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, .4);
}

.t-s {
    margin-top: 40px;
}

.t-s h2 {
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 20px;
}

.comment-content {
    margin: 0 0 0 35px;
    background: #fff;
    padding: 20px 20px 20px 55px;
}

.comment p{
    margin-bottom: 0;
    font-size: 15px;
}

.mrg-bdr {
    margin-bottom: 0;
    border-bottom: none;
    padding: 0;
}

.comment-content h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.comment-content h6 span{
    font-weight: 500;
    font-size: 15px;
}

.comment-content h6 span a{
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    background: #fff;
}

.comment-content .comment-rating {
    color: orange;
}

.comments ul {
    list-style: none;
    margin: 0 0 0 80px;
    padding: 0;
}

.comment-b .comment-content {
    padding-bottom: 0;
    border-bottom: transparent;
    margin: 0 0 0 85px;
}

.comment-b .comment-content p{
    margin-bottom: 0;
}

.comment-b{
    margin-bottom: 0;
}

/** Coming soon start **/
.coming-soon {
    background: rgba(0, 0, 0, 0.04) url(../img/banner/img-3.png) top left repeat;
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.coming-soon h1 {
    font-weight: 600;
    font-size: 65px;
    margin-bottom: 35px;
    color: #fff;
    text-transform: uppercase;
}

.coming-soon h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.coming-soon ul li {
    display: inline-block;
}

.coming-soon ul li a {
    color: #fff;
    width: 45px;
    font-size: 18px;
    height: 45px;
    display: inline-block;
    line-height: 45px;
    margin: 2px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
}

.coming-soon ul li a:hover{
    background: #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #fff;
    box-shadow: 0px 0px 0px 2px #fff;
}

.coming-soon-info .coming-form{
    max-width: 445px;
    margin: 0 auto;
}

.coming-soon  .bordered-right {
    border-right: 1px solid #848484;
}

.coming-soon .form-control {
    width: 67%;
    height: 50px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    margin-right: 0;
    outline: 0;
    border: 1px solid #ebebeb;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.coming-soon .btn, .coming-soon-info .btn:hover, .coming-soon-info .btn:active {
    width: 33%;
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
}

.coming-soon img{
    margin-bottom: 10px;
    height: 50px;
}
.coming-soon-counter {
    margin-bottom: 30px;
}

.coming-soon-counter div {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin: 0 10px 10px;
    width: 140px;
    padding: 10px 2px 20px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.2);
}

.coming-soon-counter div:last-child {
    border: none;
}

.coming-soon-counter div span {
    display: block;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    color: #fff;
}

/** Contact 1 area start **/
.contact-1 .contact-innner{
    margin: 0 auto -100px;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgb(62 65 159 / 10%);
}

.contact-1 .contact-info {
    padding: 70px;
    position: relative;
    z-index: 999!important;

}

.contact-1 .contact-form {
    padding: 70px;
    border-right: solid 1px #e5e5e5;
}

.contact-1 .contact-info h3 {
    font-size: 25px;
    font-weight: 500;
}

.contact-1 .contact-info .mb-30{
    margin-bottom: 30px!important;
}

.contact-1 .contact-form h3{
    font-size: 25px;
    font-weight: 500;
}

.contact-1 .contact-info .ci-box {
    position: relative;
}

.contact-1 .contact-info .ci-box i {
   /* font-size: 25px;
    margin-top: 5px;*/
}

.contact-1 .contact-info .ci-box .detail {
    margin: 5px 0 0 20px;
}

.contact-1 .contact-info .ci-box h4 {
    font-weight: 500;
    font-size: 19px;
    margin: 0 0 5px;
}

.contact-1 .contact-info .ci-box p {
    font-size: 16px;
    color: #5a5959;
    margin-bottom: 0;
}

.contact-1 .contact-info .ci-box p a {
    color: #5a5959;
}

.contact-1 .form-control {
    width: 100%;
    font-size: 15px;
    outline: 0;
    border-radius: 3px;
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
}

.contact-1 .form-control:focus {
    color: #495057;
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    outline: 0;
}

.contact-1 .form-control:focus {
    box-shadow: none;
}

.contact-1 .form-floating>label {
    padding: 1rem 1rem;
}

.contact-1 .form-floating {
    color: #5a5959;
}

.contact-1 .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.contact-1 textarea {
    min-height: 220px;
}

.contact-1 .btn-message {
    border-radius: 50px;
    padding: 10px 60px 11px;
}

.contact-1 .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .90;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.contact-1 .btn-5 {
    padding: 13.5px 30px 12px;
    height: 58px;
    font-weight: 500;
    font-size: 15px;
}

.contact-map {
    height: 350px !important;
    z-index: -1;
}

/** Contact 2 start **/
.contact-2 .contact-info {
    text-align: center;
}

.contact-2 .contact-info strong {
    font-weight: 500;
    font-size: 14px;
    color: #5a5959;
}

.contact-2 .contact-info i {
    font-size: 24px;
    color: #212529;
}

.contact-2 .contact-info p {
    margin: 0;
    font-size: 15px;
}

.contact-2 .form-control {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    background: #fff;
    outline: 0;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #d7d5d5;
    box-shadow: none;
}

.contact-2 .form-control:focus {
    color: #495057;
    background: #fff;
    outline: 0;
    border: 1px solid #d7d5d5;
    box-shadow: none;
}

.contact-2 .form-group {
    margin-bottom: 20px;
}

.contact-2 textarea {
    min-height: 230px;
}

.contact-2 .btn-message {
    border-radius: 50px;
    padding: 10px 60px 11px;
}

.contact-map {
    height: 350px !important;
}

.contact-map-content .map-content {
    width: 250px;
}

/** Sidebar nav start **/
.sidebar-logo{
    padding: 20px 30px;
    border-bottom: solid 1px #00000024;
}

.nav-sidebar {
    width: 350px;
    position: fixed;
    top: 0;
    left: -350px;
    height: 100vh;
    z-index: 9999;
    background: #fff;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.nav-sidebar.active {
    left: 0;
}

#sidebar .sidebar-inner{
    padding: 0!important;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: transparent;
    position: absolute;
    top: 23px;
    font-size: 20px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#dismiss:hover {
    background: #fff;
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.main-header .dropdown-toggle::after {
    top: 35%;
    right: 20px;
}

.sidebar-nav {
    display: block;
    z-index: 11;
    overflow: hidden;
    width: 100%;
}

.sidebar-nav-list{
    padding: 0px;
    margin: 0px;
}

.sidebar-nav-list .nav-header {
    margin-bottom: 0;
    color: #eee;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 15px 10px;
}

.sidebar-nav-list li {
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.sidebar-nav-list li a{
    color: #fff !important;
    font-size: 14px;
    padding-left: 30px;
}

.sidebar-nav-list .badge{
    display: inline-block;
    float: right;
    background: #0aa587;
    color: #fff;
}

/** Sidebar nav end **/
.sidebar-navigation {
    height: auto;
    margin: 0 auto;
    padding: 30px 20px 20px;
    border-bottom: solid 1px #00000024;
}

.sidebar-navigation ul {
    margin: 0;
    padding: 0;
}
.sidebar-navigation ul li {
    display: block;
}

.sidebar-navigation ul li a {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 10px 5px;
    text-decoration: none;
    color: #000;
    letter-spacing: normal;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.sidebar-navigation .pt0{
    padding-top: 0;
}

.sidebar-navigation .menu-list li a .badge {
    float: right;
    padding: 3px 10px;
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
}

.sidebar-navigation .menu-list li a .badge {
    float: right;
    padding: 3px 10px;
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
    color: #fff;
}

.sidebar-inner h3{
    margin: 0 0 20px;
    padding: 0 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    color: #000;
}

.sidebar-navigation ul li a em {
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4.5px 5px 5.5px;
    border-radius: 5px;
    font-weight: 100;
    color: #777;
}
.sidebar-navigation ul li:hover > a, .sidebar-navigation ul li.selected > a {
    background-color: transparent;
    color: #495d62;
    border-color: rgba(255, 255, 255, 0.1);
}

.sidebar-navigation ul li ul {
    display: none;
    background-color: transparent;
}
.sidebar-navigation ul li ul.open {
    display: block;
}
.sidebar-navigation ul li ul li a {
    color: #000;
    border-color: rgba(255, 255, 255, 0.1);
    font-size: 15px;
    font-weight: 500;
    padding:8px;
}

.sidebar-navigation ul li ul li:hover > a, .sidebar-navigation ul li ul li.selected > a {
    background-color: transparent;
}
.sidebar-navigation ul li ul li:hover > a:before, .sidebar-navigation ul li ul li.selected > a:before {
    margin-right: 10px;
}
.sidebar-navigation ul li ul li.selected.selected--last > a {
    background-color: #94aab0;
    color: #fff;
}
.sidebar-navigation ul li ul li.selected.selected--last > a:before {
    background-color: transparent;
}

/** Get social start **/
.get-social {
    height: auto;
    margin: 0 auto;
    padding: 30px 30px;
    border-bottom: solid 1px #00000024;
}

.get-social a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    margin-right: 2px;
    border-radius: 5%;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}

.get-social h3 {
    padding: 0;
}
/** Get social end **/

/** Get in touch start **/
.get-in-touch {
    height: auto;
    margin: 0 auto;
    padding: 30px 30px;
    border-bottom: solid 1px #00000024;
}

.get-in-touch h3 {
    padding: 0;
}

.get-in-touch .get-in-touch-box {
    margin-bottom: 10px;
}

.get-in-touch .get-in-touch-box i {
    width: 40px;
    height: 40px;
    text-align: center;
    background: #f1e6e6;
    line-height: 40px;
    margin-right: 15px;
    font-size: 16px;
    color: #000;
    border-radius: 3px;
}

.get-in-touch .get-in-touch-box a{
    color: #000;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 0;
    font-weight: 500;
}

/** Get in touch end **/

/** Social media box **/
.social-media-box .social-list li {
    float: left;
}

.social-media-box .social-list li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    margin: 0 6px 4px 0;
    border-radius: 3px;
}

/** Financing calculator **/
.financing-calculator .form-label {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #2e2d2d;
    font-family: 'Prompt', sans-serif;
}

.financing-calculator .form-group {
    margin-bottom: 20px;
}

.mt-30 {
    margin-top: 30px;
}

/** Service info srart **/
.single-info {
    padding: 40px 30px 40px 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    z-index: 3;
    background: #fff;
}

.single-info:before {
    position: absolute;
    content: '';
    height: 35px;
    width: 35px;
    top: 0;
    right: 0;
    z-index: -1;
    border-radius: 0 0px 0px 70px;
    margin-top: 0px;
    -webkit-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -o-transition: .5s all ease;
    transition: .5s all ease;
}

.single-info:hover:before {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.single-info .icon {
    margin-bottom: 10px;
}

.single-info .icon-inner {
    position: relative;
}

.single-info .icon i, .single-info .icon-inner i {
    position: relative;
    font-size: 45px;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
    display: inline-block;
}

.single-info:hover .icon i, .single-info:hover .icon-inner i {
    -webkit-transform: scale(-1) rotate(180deg);
    -moz-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    -o-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}

.single-info h2{
    font-size: 22px;
    position: relative;
    margin-bottom: 10px;
}

.single-info p {
    margin-bottom: 0;
}

.single-info .number {
    font-size: 170px;
    line-height: 1;
    display: inline-block;
    position: absolute;
    z-index: 0;
    right: 10px;
    font-weight: 600;
    bottom: 0;
    color: transparent;
    -webkit-text-stroke: 1px #444444;
    padding-left: 2px;
    font-family: 'Prompt', sans-serif;
}

.single-info:hover .number{

    -webkit-text-stroke: 1px #fff;
}

.single-info:hover p{
    color: #fff;
}

.single-info:hover h2 a{
    color: #fff!important;
}

.single-info:hover .icon i{
    color: #fff;
}

/** Content area srart **/
.content-area {
    padding: 30px 0 70px;
}

.content-area-2 {
    margin-bottom: 100px;
}

.content-area-3 {
    margin-bottom: 70px;
}

.content-area-4 {
    padding: 30px 0 50px;
}

.content-area-5 {
    padding: 100px 0;
}

.content-area-6 {
    padding: 100px 0 50px;
}

.content-area-7 {
    padding-bottom: 80px;
}

.content-area-8 {
    padding: 185px 0 70px;
}

.content-area-9 {
    padding: 100px 0 90px;
}

.content-area-10 {
    padding: 100px 0 60px;
}

.content-area-11 {
    padding: 100px 0;
}

.content-area-12 {
    padding: 100px 0 80px;
}

.content-area-13 {
    padding: 100px 0 80px;
}

.content-area-14 {
    margin-bottom: 90px;
}

.content-area-15 {
    padding: 100px 0 10px;
}

.content-area-16{
    padding: 100px 0 90px;
}

.content-area-17{
    padding: 100px 0 80px;
}

.content-area-18{
    padding: 185px 0 100px;
}

.content-area-19{
    padding: 100px 0 50px;
}

.content-area-19{
    padding: 100px 0 50px;
}

.content-area-20{
    padding-top: 100px;
}

.content-area-21{
    padding: 30px 0 90px;
}

.content-area-22{
    padding-top: 0;
}

.about-car{
    padding: 100px 0 115px;
}

.theme img {
    margin-bottom: 30px;
}

/** Paginatin box **/
.pagination-box {
    margin-bottom: 25px;
}

.pagination {
    padding-left: 0;
    border-radius: 4px;
    display: inline-block;
}

.pagination li {
    display: inline-block;
}

.pagination > li > a {
    color: #212121;
    font-size: 18px;
    background: #fff;
    font-weight: 400;
}

.pagination > li > a i{
    font-size: 15px;
    font-weight: 600;
}

.pagination > li > a, .pagination > li > span {
    margin: 0 2px 5px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    border: none;
    padding: 0;
    border-radius: 3px;
    background: #fff;
}

.pagination .page-item:first-child .page-link {
    border-radius: 3px;
}

.pagination .page-item:last-child .page-link {
    border-radius: 3px;
}

.pagination > li > a:hover {
    color: #fff;
}

.pagination > li > .active {
    color: #fff;
}

.page-link:focus {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.show-more-options {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
    line-height: 30px;
    font-family: 'Prompt', sans-serif;
    color: #212121!important;
}

.show-more-options i{
    font-size: 20px;
    line-height: 30px;
    margin-right: 3px;
}

/** Slide box btn start **/
.slide-box-btn .slick-prev{
    display: none!important;
}

.slide-box-btn .slick-next{
    display: none!important;
}

.slide-box{
    padding: 0 15px;
}

/**  Partners start **/
.partners {
    text-align: center;
    padding: 40px 0 30px;
    background: #fff;
}

.partners img{
    max-width:170px;
    margin: 0 auto;
}

.partners .custom-slider {
    width: 100%;
    margin: auto;
}

.partners .slick-slider{
    user-select: none;
}

.partners .slick-next{
    right: 0;
    display: none!important;
}

.partners .slick-prev{
    left: 0;
    display: none!important;
}

/**  S-border start **/
.s-border {
    width: 50px;
    height: 1px;
    margin-bottom: 2px;
    border-radius: 50px;
    background: #c5c5c5;
}

.m-border {
    width: 25px;
    height: 1px;
    margin-bottom: 30px;
    border-radius: 50px;
    background: #c5c5c5;
}

.sidebar-right .m-border{
    margin-bottom: 20px;
}

.sidebar-left .m-border{
    margin-bottom: 20px;
}

/** Navbar buttons start **/
.navbar-buttons img{
    width: 45px;
    border-radius: 50px;
    margin: 20px 15px 20px 0;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.navbar-buttons{
    float: right;
    text-align: right;
    font-weight: 500;
}

.navbar-buttons ul li{
    float: left;
    margin-left: 35px;
}

.navbar-buttons .btn-md{
    border-radius: 50px;
}

.header-2{
    padding: 0 15px;
}

/** Contact-section start **/
.contact-section{
    background: #eef1f6;
}

.contact-section a {
    text-decoration: none;
}

.contact-section .bg-img{
    background: url(../img/banner/img-4.png) top left repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    text-align: left;
    z-index: 10;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 0;
}

.contact-section .bg-img:after {
    position: absolute;
    right: -1px;
    top: 0;
    width: 288px;
    height: 100%;
    content: "";
    z-index: -1;
    background: url(../img/img-5.png) top left repeat;
    min-height: 100vh;
}

.contact-section .form-check-input:checked {
    display: none;
}

.contact-section .form-section{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.contact-section .form-section .form-box {
    width: 100%;
    position: relative;
}

.contact-section .login-inner-form {
    max-width: 450px;
    color: #5a5959;
    width: 100%;
    text-align: center;
}

.contact-section .login-inner-form p{
    color: #5a5959;
    font-size: 16px;
    margin: 0;
}

.contact-section .login-inner-form p a{
    color: #5a5959;
    font-weight: 500;
}

.contact-section .login-inner-form img {
    margin-bottom: 5px;
    height: 50px;
}

.contact-section .login-inner-form h3 {
    margin: 0 0 30px;
    font-size: 22px;
    font-weight: 500;
}

.contact-section .login-inner-form .form-group {
    margin-bottom: 25px;
}

.contact-section .login-inner-form .form-control {
    outline: none;
    width: 100%;
    padding: 10px 25px;
    font-size: 16px;
    outline: 0;
    font-weight: 400;
    color: #5a5959;
    height: 55px;
    border-radius: 3px;
    border: 1px solid #fff;
}

.contact-section .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
}

.contact-section .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.contact-section .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.contact-section .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.contact-section .login-inner-form .btn-theme {
    border: none;
    color: #fff;
}

.contact-section .login-inner-form .checkbox .terms {
    margin-left: 3px;
}

.contact-section .informeson {
    color: #fff;
    max-width: 450px;
    margin: 0 auto 0 150px;
}

.contact-section .informeson h1{
    color: #fff;
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: 600;
    text-transform: uppercase;
}

.contact-section .informeson p{
    color: #ebe8e8;
    margin-bottom: 25px;
    line-height: 25px;
    font-size: 15px;
}

.contact-section .none-2{
    display: none;
}

.contact-section .btn-section {
    margin-bottom: 30px;
}

.contact-section .login-inner-form .terms{
    margin-left: 3px;
}

.contact-section .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.contact-section .login-inner-form .form-check a {
    color: #5a5959;
    float: right;
}

.contact-section .login-inner-form .form-check-input {
    display: none;
}

.contact-section .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    top: 2px;
    height: 20px;
    margin-left: -7px;
    border: 1px solid #fff;
    border-radius: 2px;
    background-color: #fff;
}

.contact-section .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
}

.contact-section .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 17px;
    font-size: 14px;
    content: "\2713";
    padding-left: 1px;
}

.contact-section .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.contact-section .login-inner-form .checkbox a {
    font-size: 16px;
    color: #5a5959;
    float: right;
}

.contact-section h1 {
    color: #fff;
    font-size: 35px;
    margin-bottom: 20px;
}

.contact-section .typing > *{
    overflow: hidden;
    white-space: nowrap;
    animation: typingAnim 3s steps(50);
}

@keyframes typingAnim {
    from {width:0}
    to {width:100%}
}

/* Social list */
.contact-section .social-list a {
    transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s, border-top-right-radius 0.1s linear 0.1s, border-bottom-right-radius 0.1s linear 0.2s, border-bottom-left-radius 0.1s linear 0.3s;
    border-radius: 50%;
}

.contact-section .social-list a i {
    transition: transform 0.4s linear 0s;
}

.contact-section .social-list a:hover {
    transform: rotate(360deg);
    border-radius: 50px;
}

.contact-section .social-list a:hover i {
    transform: rotate(-360deg);
}

.contact-section .social-list .buttons {
    display: flex;
}

.contact-section .social-list a {
    text-decoration: none !important;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin:0 4px 5px;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    background: #fff;
}

.contact-section .social-list a i {
    position: relative;
    z-index: 3;
}

.contact-section .social-list a.facebook-bg {
    color: #4867aa;
}

.contact-section .social-list a.twitter-bg {
    color: #33CCFF;
}

.contact-section .social-list a.google-bg {
    color: #db4437;
}

.contact-section .social-list a.dribbble-bg {
    color: #2392e0;
}
/** Login 3 end **/

/** Sidebar widget start **/
.sidebar-right {
    margin: 0 0 30px 0;
    background: #fff;
    position:sticky;
    top:0;
}

.sidebar-left {
    margin: 0 0 30px 0;
    background: #fff;
}

.widget {
    padding: 35px;
    border-bottom: solid 1px #e2e2e2;
}

.widget-2 {
    margin-bottom: 40px;
    padding: 30px 20px;
}

.widget-2 .m-border{
    margin-bottom: 20px;
}

.widget-3 {
    border-bottom: none;
}

.single-block {
    margin-bottom: 30px;
}

.sidebar-title {
    font-size: 22px;
    position: relative;
    margin: 0 0 7px;
    font-weight: 500;
}

.sidebar .search-box {
    padding: 20px 20px 0 20px;
    margin-bottom: 0;
}

.form-search {
    position: relative;
}

.form-search .form-control {
    box-shadow: none;
    border: 1px solid #F7F7F7;
    background: #F7F7F7;
    border-radius: 3px;
    height: 50px;
}

.form-search .btn {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 12px 9px 13px;
}

.form-search .btn:active,
.form-search .btn:focus {
    box-shadow: none;
    outline: none;
}

.form-search .btn i {
    color: #777;
}

.form-search .form-group, .form-search .form-control {
    width: 100%;
}

.range-slider {
    margin-top: 15px;
    margin-bottom: 30px;
    font-family: 'Prompt', sans-serif;
}

.range-slider .ui-slider {
    position: relative;
    text-align: left;
}

.range-slider label {
    font-size: 14px;
    color: #303030;
    font-weight: 600;
    margin-bottom: 12px;
}

.range-slider .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 14px;
    height: 14px;
    border-radius: 0;
    border: 2px solid #66676b;
    cursor: w-resize;
    outline: none;
    top: -5px;
    margin-left: -8px;
    box-shadow: none !important;
}

.range-slider .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0;
    background-position: 0 0;
}

.range-slider .ui-slider .ui-slider-handle {
}

.range-slider .ui-widget-content {
    background-color: #ccc;
}

.range-slider .ui-widget-header {
}

.range-slider .ui-slider.ui-state-disabled .ui-slider-handle, .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.range-slider .ui-slider-horizontal {
    height: 4px;
    border-radius: 10px;
    width: calc(100% - 16px);
    margin-left: 8px;
}

.range-slider .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.range-slider .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.range-slider .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.min-value:focus, .max-value:focus, .min-value, .max-value {
    width: 40%;
    display: inline-block;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 12px 0 0 0;
    cursor: default;
}

.min-value {
    float: left;
    margin-left: -8px;
    width: 40%;
    margin-right: -8px;
    font-size: 13px;
    color: #303030;
    font-weight: 600;
    margin-top: 15px;
}

.max-value {
    float: right;
    width: 60%;
    text-align: right;
    margin-right: -8px;
    font-size: 13px;
    color: #303030;
    font-weight: 600;
    margin-top: 15px;
}

.sidebar .recent-posts-box .detail header {
    padding-bottom: 0;
}

.search-box-2{
    padding: 30px 0;
}

.search-box-3{
    padding: 30px 0 10px;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.search-box-3 .button-theme{
    color: #fff;
    font-weight: 600;
}

.search-box-3 i{
    margin-right: 3px;
}

.search-box-3 .form-group {
    margin-bottom: 20px;
}

.search-box-3 .range-slider {
    margin-top: 10px;
    margin-bottom: 20px;
}

.search-box-3 .min-value {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Prompt', sans-serif;
}

.search-box-3 .max-value {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Prompt', sans-serif;
}

.sb-5 {
    transition: all 0.4s;
    position: absolute;
    bottom: -85px;
    width: 100%;
    background: transparent;
    box-shadow: none;
    padding: 0;
    z-index: 999;
}

.sb-5 .btn-md{
    padding: 15px 30px 14px 30px;
}

.sb-5 .search-area-inner{
    padding: 30px 30px 10px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
}

.sb-5 .search-area-inner .search-fields {
    border: 1px solid #fff;
}

.sb-5 .range-slider .ui-widget-header {
    background-color: #fff;
}

.sb-5 .ui-widget-content {
    background-color: #000;
}

.sb-5 .range-slider .ui-slider .ui-slider-handle {
    border: 2px solid #fff;
}

.sb-5 .range-slider .ui-slider .ui-slider-handle {
    background: #fff;
}

.sb-5 .button-theme {
    background: #000;
    width: 100%;
}

.sb-5 .button-theme:hover {
    background: #252525;
}

.sb-5 .min-value {
    color: #fff;
    font-family: 'Prompt', sans-serif;
}

.sb-5 .max-value {
    color: #fff;
    font-family: 'Prompt', sans-serif;
}

.sb-6{
    display: none;
}

.white-btn{
    background: #24272b;
    color: #fff;
    font-size: 14px;
}

.text-l{
    text-align: left!important;
}

.text-r{
    text-align: right!important;
}

.search-box-4{
    background-color: #fff;
    padding: 30px;
    max-width: 320px!important;
    margin: 0 0 0 auto;
    width: 100%;
    z-index: 1;
    border-radius: 5px;
}

.bi5{
    margin-top: 100px;
}

.banner-content3{
    margin-top: 44px;
}

.search-box-4 .search-fields {
    min-height: 40px;
    padding: 0;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #fff;
    background: #fff;
}

.bootstrap-select>.dropdown-toggle:after {
    margin-top: 0;
}

.search-box-4 .btn-md {
    padding: 14.5px 30px;
}

.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 0;
    text-indent: -999px;
    background-color: rgba(255,255,255,.5);
}

/** Sub Banner start **/
.sub-banner {
    background: rgba(0, 0, 0, 0.04) url(../img/img-5.png) top left repeat;
    background-size: cover;
   
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

.sub-banner .breadcrumb-area {
    letter-spacing: 1px;
    position: absolute;
    top: 49%;
    right: 0;
    left: 0;
}

.mb-7{
    margin-bottom: 7px;
}

.sub-banner .breadcrumb-area h1 {
    margin: 0;
    font-weight: 600;
    font-size: 55px;
    color: #fff;
    text-transform: uppercase;
}

.sub-banner .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.sub-banner .breadcrumbs li a{
    color: #f1efef;
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
}

.sub-banner .breadcrumbs .active {
    margin-left: 3px;
    color: #fff;
}

.sub-banner .breadcrumbs .active:before {
    margin-right: 7px;
    content: "\f0da";
    padding: 0 3px;
    color: #fff;
    font: normal normal normal 14px/1 FontAwesome;
}

.blog-social-list {
    float: right;
    font-family: 'Prompt', sans-serif;
}

.blog-social-list a {
    text-align: center;
    display: inline-block;
    list-style: none;
}

.blog-social-list span {
    margin-right: 10px;
    font-weight: 500;
    color: #212121;
}

.blog-social-list a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin: 2px 0;
    border-radius: 5%;
}

.blog-tags a {
    border: 1px solid #f3f3f3;
    background: #f3f3f3;
    color: #5a5959;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 5px;
    padding: 6px 13px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 3px;
}

.blog-tags span {
    margin-right: 10px;
    font-weight: 600;
    color: #212121;
    font-family: 'Prompt', sans-serif;
}

/** Details banner start **/
.details-banner{
    padding: 0;
    position: relative;
}

.details-banner .text h1 {
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
}

.details-banner .text .ratings-2 span {
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
}

.details-banner .text .ratings-box {
    margin-right: 4px;
    padding: 4px 10px;
    color: #fff;
    border-radius: 3px;
}

.details-banner .text .ratings-2 i {
    color: orange;
}

.details-banner .text .ratings-2 span {
    color: #fff;
}

.details-banner .breadcrumb-area-2 {
    letter-spacing: 1px;
    width: 100%;
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
}

.details-banner .cover-buttons{
    margin-top: 32px;
}

.details-banner .cover-buttons ul li {
    display: inline-block;
}

.details-banner .cover-buttons ul li a {
    color: #eaeaea;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.36);
}

.details-banner .cover-buttons ul li a:hover {
    color: #fff;
}

.details-banner .slide-box{
    padding: 0;
}

.details-banner img{
    height: 500px;
    width: 100%;
    cursor: pointer;
}

.details-banner .breadcrumb-area-2 {
    letter-spacing: 1px;
    width: 100%;
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    color: #fff!important;
}

/** My account box start **/
.my-address form .form-group {
    margin-bottom: 20px;
}

.my-address form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 14px;
    border: 1px solid #e8e7e7;
    outline: none;
    height: 45px;
    border-radius: 3px;
    line-height: 30px;
}

.my-address form textarea {
    min-height: 150px;
}

.my-address form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #827f7f;
}

.my-address {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, .08);
}

.edit-profile-photo {
    position: relative;
    box-shadow: 0 0 10px 1px rgb(228, 228, 228);
    padding: 2px;
}

.change-photo-btn {
    display: inline-block;
    position: absolute;
    font-size: 13px;
    left: 0%;
    right: 0%;
    width: auto;
    transition: 0.3s;
    cursor: pointer;
    line-height: 28px;
    margin: 20px 0;
}

.photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 15px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}

.photoUpload span {
    color: #fff;
    font-weight: 600;
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.my-address {
    margin-bottom: 30px;
}

.footer .listing-meta span a {
    text-decoration: none;
    float: right;
    margin-left: 15px;
    color: #5a5959;
}

.footer .listing-meta span a i {
    margin-right: 4px;
}

.footer .listing-meta span a:hover {
    color: #8c8c8c;
}

.heading {
    margin: 0 0 30px;
    /* font-size: 20px; */
    font-weight: 600;
}

.heading-2 {
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 500;
}

.heading-4 {
    margin-bottom: 30px;
}

.heading-4 h4 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
}

.form-group label, .checkbox label {
    font-weight: 400;
    font-size: 15px;
    color: #5a5959;
}

/** Dashboard nav start **/
.dashboard{
    position: relative;
}

.fixed-header{
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #fff;
}

.dashboard-nav {
    display: block;
    height: 100%;
}

.dashboard-nav h4 {
    margin-bottom: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 30px 15px;
}

.dashboard-nav ul {
    border-bottom: 1px solid #343a40;
}

.dashboard-nav ul li.active, .dashboard-nav ul li:hover {
    border-color: #fff;
}


.dashboard-nav ul li {
    border-left: 3px solid transparent;
    line-height: 25px;
    font-weight: 400;
    font-size: 15px;
}

.dashboard-nav ul li a {
    color: #fff;
    display: block;
    padding: 11px 27px;
    transition: .3s;
    cursor: pointer;
}

.dashboard-nav ul li a:hover{
    color: #c5c5c5;
}

.dashboard-nav ul li a i {
    padding-right: 8px;
    width: 20px;
}

.dashboard-content {
    padding: 30px 30px 0;
}

.dashboard-header {
    margin-bottom: 20px;
}

.dashboard-header .breadcrumb-nav {
    right: 15px;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    float: right;
}

.dashboard-header .breadcrumb-nav ul li {
    display: inline-block;
    list-style: none;
    margin: 0 0 0 15px;
    font-weight: 500;
}

.dashboard-header h4{
    font-weight: 600;
    font-size: 25px;
}

.dashboard-list {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.dashboard-list h3 {
    padding: 15px 25px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    margin: 0;
    width: 100%;
    font-weight: 600;
}

.dashboard-message {
    float: left;
    padding: 25px 25px;
    position: relative;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.dashboard-list h4 {
    padding: 15px 25px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.responsive-table {
    padding: 20px;
    display: inline-block;
    border-bottom: 1px solid #eee;
    width: 100%;
    font-weight: 500;
}

.dashboard-nav ul li span.nav-tag {
    font-weight: 600;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    padding: 0 7px;
    text-align: center;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-left: 5px;
    background: #fff;
}

.dashboard-list .comment-content {
    padding-bottom: 20px;
    margin-bottom: 0;
}

.sub-banner-2{
    margin: 0 15px 30px;
    font-size: 15px;
}

.badge-pink {
    background-color: #ff8acc;
    color: #fff;
}

.badge-purple {
    background-color: #5b69bc;
    color: #fff;
}

/** Tab box 2 start **/
.tab-box-2 .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: transparent;
    border-radius: 0;
}

.tab-box-2 .nav-item{
    float: right;
    display: inline-block;
}

.tab-box-2 .nav-link {
    display: block;
    padding: 8px 10px;
    font-weight: 500;
}

.comments-tr{
    border-bottom: 1px solid #eee;
}

.comments-tr span{
    font-size: 20px;
    margin: 0;
    font-weight: 600;
}

.tab-box-2 .comment-content {
    padding-bottom: 0;
    border-bottom: none;
}

.tab-box-2 .comment-content p{
    margin-bottom: 0;
}

/** Ui item start **/
.ui-item {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 30px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 50px;
}

.ui-item i {
    font-size: 70px;
    position: absolute;;
    right: 20px;
    top:  20px;
    color: rgba(255,255,255,0.41);
}

.ui-item p{
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
}

.ui-item h4{
    color: #fff;
    font-weight: 600;
    font-size: 30px;
}

.alert-2{
    margin-bottom: 50px;
}

/** typography start **/
.typography .heading-3 {
    margin: 0 0 20px;
    font-weight: 600;
}

/** Car amenities start **/
.car-amenities ul li {
    margin-bottom: 15px;
    font-size: 15px;
    padding-left: 27px;
    position: relative;
    color: #424141;
}

.car-amenities ul li i {
    left: 0;
    position: absolute;
    top: 3px;
}

.search-banner .show-more-options {
    margin-bottom: 20px;
    display: block;
}

.features-info ul li {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 15px;
}

.features-info ul li strong {
    margin-right: 5px;
    font-weight: 500;
}

.car-details-page .heading {
    margin: 0 0 25px;
    font-size: 20px;
    font-weight: 600;
}

.car-details-page iframe, .modal-body iframe .element.style {
    height: 350px;
}

.car-details-page iframe, .modal-body iframe {
    width: 100%;
    border: none;
}

/** Heading car start **/
.heading-car{
    margin-bottom: 20px;
}

.heading-car h3{
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 5px;
}

.heading-car p{
    font-size: 15px;
    margin-bottom: 0;
    color: #5a5959;
}

.heading-car h6 i{
    margin-right: 3px;
}

.heading-car-2 {
    letter-spacing: 1px;
    float: left;
    position: absolute;
    top: 25px;
    left: 25px;
    padding: 20px 20px;
    z-index: 20;
    font-size: 13px;
    letter-spacing: 1px;
    color: #e2e1e1;
    font-weight: 500;
    border-radius: 5px;
    font-family: 'Prompt', sans-serif;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
}

.heading-car-2 h3 {
    font-size: 27px;
    font-weight: 500;
    margin: 0 0 15px;
    color: #fff;
    letter-spacing: 0px;
}

.heading-car-2 .price-location {
    font-size: 14px;
    font-family: 'Prompt', sans-serif;
}

.heading-car-2 .car-price{
    color: #fff;
    font-weight: 600;
    margin-right: 5px;
}

.heading-car-2 .location {
    font-weight: 400;
    color: #fff;
}

.heading-car-2 .rent {
    padding: 5px 20px 4px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    background: #fff;
}

/** Car details sliders start **/
.car-details-sliders .carousel-indicators {
    margin-left: initial;
    margin-top: 0;
}

.car-details-sliders .carousel-indicators a {
    cursor: pointer;
}

.car-details-sliders .carousel-indicators > li {
    height: initial;
    text-indent: initial;
    margin-right: 0;
    margin-left: 0;
}

.dd.car-details-sliders .carousel-indicators > li {
    width: initial;
    height: initial;
    text-indent: initial;
}

.car-details-sliders .list-inline {
    width: 100%;
    bottom: 10px;
    position: absolute;
}

.car-details-sliders .list-inline a{
    margin-right: 0;
}

.car-properties .list-inline-item {
    width: 80px;
    margin: 5px!important;
    border-radius: 5%;
}

.car-properties .list-inline-item a img {
    width: 100%;
    border: none;
    border-radius: 5%;
    box-shadow: 0 1px 20px 0 rgb(0 0 0 / 28%);
}

.car-properties .list-inline-item a .car-image{
    box-shadow: 0 1px 20px 0 rgb(0 0 0 / 0%);
}

.car-details-sliders .carousel-indicators > li.active img {
    opacity: 0.7;
}

.car-details-sliders .right {
    position: absolute;
    right: 15px;
    top: 45%;
}

.car-details-sliders .left {
    left: 10px;
    top: 47%;
    width: 30px;
    height: 30px;
    line-height: 33px;
    position: absolute;
    z-index: 5;
    display: inline-block;
    right: -30px;
    text-align: center;
    background: #a5a5a5;
    border-radius: 5%;
}

.car-details-sliders .left i {
    font-size: 20px;
    color: #212121;
}

.car-details-sliders .right {
    right: 10px;
    top: 47%;
    width: 30px;
    height: 30px;
    line-height: 33px;
    position: absolute;
    z-index: 5;
    display: inline-block;
    text-align: center;
    background: #a5a5a5;
    border-radius: 5%;
}

.car-details-sliders .right i {
    font-size: 20px;
    color: #212121;
}

.list-inline-item:not(:last-child) {
    margin-right: 1px;
}

.car-properties {
    width: 100%;
}

/** Carousel indicators section start **/
.carousel-indicators-section{
    width: 100%;
}

.carousel-indicators-section .car-properties .list-inline-item {
    width: 20%;
    margin: 0!important;
    border-radius: 0!important;
}

.carousel-indicators-section .carousel-indicators .active {
    background: rgb(12 62 185 / 88%);
}

.carousel-indicators-section .list-inline-item a img {
    border-radius: 0;
}

.carousel-item img{
    width: 100%;
}

.carousel-indicators-section .carousel-indicators > li {
    height: initial;
    text-indent: initial;
    margin-right: 0;
    margin-left: 0;
}

.carousel-indicators-section .list-inline {
    width: 100%;
    bottom: inherit;
    position: absolute;
}

.carousel-indicators-section .carousel-indicators {
    margin-left: initial;
    margin-top: 0;
    position: relative;
}

/** Shop box start **/
.shop-box {
    margin-bottom: 30px;
    border: none;
    background: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
    border-radius: 0;
    text-align: center;
}

.shop-box:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.shop-box .shop-theme {
    position: relative;
}

.shop-box .shop-theme::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.shop-box .shop-theme img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 111;
    width: 100%;
    border-radius: 0;
}

.shop-box .shop-theme::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.shop-box:hover .shop-theme::before, .shop-box:hover .shop-theme::after {
    width: 100%;
    height: 100%;
    opacity: 0.2;
    visibility: visible;
}

.shop-box .price-box {
    text-align: right;
    font-size: 15px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 20;
    font-weight: 600;
}

.shop-box  .price-box .del {
    color: red;
    font-size: 13px;
}

.shop-box .price-box span {
    color: #37404d;
}

.shop-box .shop-details .btn {
    padding: 7px 20px 7px;
    font-size: 13px;
    letter-spacing: normal;
}

.shop-box .shop-details {
    padding: 25px 15px 30px;
}

.shop-box .shop-details h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.shop-box .shop-details h5 a {
    font-size: 20px;
}

.shop-box .shop-details h5 {
    font-weight: 500;
    margin-bottom: 13px;
}

.shop-box .shop-theme::before {
    background: rgb(12 62 185 / 51%);
}

.shop-box .shop-theme::after {
    background: rgb(12 62 185 / 51%);
}

/** Cart total box start **/
.cart-total-box {
    padding: 30px;
    background: #fff;
}

.cart-total-box h5 {
    font-weight: 500;
    font-size: 20px;
}

.cart-total-box ul li {
    line-height: 33px;
    width: 100%;
    font-size: 15px;
}

/** Shop checkout start **/
.shop-checkout .form-control {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    outline: 0;
    height: 50px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #eaeaea;
}

.shop-checkout .form-group {
    margin-bottom: 20px;
}

.payment-box {
    margin-left: 20px;
}

.payment-box .form-check-label {
    font-size: 20px;
    font-weight: 500;
    color: #212121;
    font-family: 'Prompt', sans-serif;
    margin-bottom: 5px;
}

.payment-box  .form-check-input:focus {
    box-shadow: none;
}

.payment-box.form-check-input[type=checkbox] {
    border-radius: 2px;
}

.payment-box .form-check-input {
    margin-left: -20px;
    border-radius: 2px;
}

.shop-info .reviews-box {
    padding: 10px 0;
    border-top: solid 1px #e9e9e9;
    border-bottom: solid 1px #e9e9e9;
    margin-bottom: 20px;
    box-shadow: none;
}

.shop-info .reviews-box ul li {
    float: left;
    font-size: 12px;
    line-height: 25px;
}

.shop-info .btn-md {
    padding: 15.5px 5px 13.5px 5px;
    letter-spacing: 0.5px;
}

.shop-info .reviews-box ul li i {
    margin-right: 1px;
    color: orange;
}

.shop-info .reviews-box .reviews {
    float: left;
    font-size: 13px;
    text-transform: uppercase;
    margin-left: 5px;
    line-height: 25px;
}

.shop-info .reviews-box .reviews a {
    margin-right: 5px;
    padding-right: 5px;
    border-right: solid 1px #777;
    color: #5a5959;
}

.shop-info .reviews-box .reviews a i {
    margin-right: 5px;
}

.shop-info .reviews-box .reviews a:last-child {
    border-right: solid 1px transparent;
    padding: 0;
    margin: 0;
}

.shop-info .price {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.shop-info .price del {
    margin-right: 15px;
}

.shop-info .horizontal ul li {
    line-height: 30px;
    width: 100%;
    font-size: 15px;
}

.shop-info .horizontal ul li span {
    width: 40%;
    float: left;
}

.shop-info p {
    line-height: 28px;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
}

.shop-info .form-control {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    background: transparent;
    outline: 0;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

/** SHOP table **/
table {
    width: 100%;
}

.shop-header {
    margin-bottom: 30px;
}

.shop-table.cart .product-thumbnail, .shop-table.cart .product-name {
    padding-left: 0;
}

.shop-table.cart .product-thumbnail, .shop-table.cart .product-name a {
    text-transform: none;
    font-size: 15px;
}

.shop-table th {
    padding: 15px 0;
    border: none;
}

.shop-table.cart td, .shop-table.cart th {
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 15px;
    color: #5a5959;
}

.shop-table.cart .t-600{
    font-weight: 600;
    font-family: 'Prompt', sans-serif;
}

.shop-table.cart .product-remove {
    padding-right: 0;
}

.shop-table td {
    border-top: 1px solid #ddd;
    padding: 20px 0;
}

.shop-table.cart .product-thumbnail img {
    width: 100px;
    padding: 2px;
    background: #eaeaea;
}

.shop-table.cart .qty {
    width: 60px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.shop-table input[type="text"], input[type="email"], textarea, input[type="password"], input[type="tel"] {
    border: 1px solid #e8e8e8;
    padding: 6px 15px;
    color: #5a5959;
    max-width: 100%;
    outline: none;
}

/** Service center start **/
.service-center iframe, .modal-body iframe {
    width: 100%;
    border: none;
    height: 400px;
}

.service-car{
    padding-right: 40px;
}

.best-used-car h3 {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.service-car .carousel-inner img{
    border-radius: 10px;
}

.service-car .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    opacity: 1;
    border-right: 3px;
    margin-bottom: 0;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
}

/** Faq info start **/
.faq-info .nav-tabs {
    background: transparent;
    border-bottom: none;
    margin-bottom: 30px;
}

.faq-info .nav-tabs .nav-link {
    display: block;
    padding: 12px 20px 12px;
    border-radius: 0;
    background: #fff;
    border: none;
    color: #5a5959;
}

.faq-info .nav-tabs .nav-link.active {
    color: #fff;
}

.faq-info .nav-tabs .nav-link:hover{
    color: #fff;
}

.faq-info .accordion {
    background: #fff;
    color: #5a5959;
    cursor: pointer;
    padding: 15px 20px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
}

.faq-info .active, .accordion:hover {
    color: #5a5959;
}

.faq-info .panel {
    padding: 0 20px;
    background: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom:10px;
}

.faq-info .accordion:after {
    content: '\002B';
    color: #1f1f1f;
    font-weight: 400;
    float: right;
    font-size: 20px;
    margin-left: 5px;
}

.faq-info .accordion.active:after {
    content: "\2212";
    float: right;
    font-size: 20px;
    margin-left: 5px;
}

.faq-info .panel p {
    padding: 20px 0px;
    margin-bottom: 0;
    border-top: solid 1px #c1c1c18c;
}

.faq-body{
    background: #fff;
}

.faq-body .image {
    position:relative;
    -webkit-animation:glide 2s ease-in-out alternate infinite;
}

.faq-body .image .image-inner{
    max-width: 460px;
    margin: 30px 0 0 auto;
}

@-webkit-keyframes glide  {
    from {
        left:0;
        top:0;
    }

    to {
        left:0;
        top:20px;
    }
}

.faq-body .accordion {
    background: #f9f9f9;
}

.faq-body .panel {
    background: #f9f9f9;
}

.faq-body .faq-info .nav-tabs .nav-link {
    background: #f9f9f9;
}
.nav-tabs .nav-link {
    color:#F26623;
}

/** Tabbing box start **/
.tabbing-box .tab-pane {
    padding-top: 30px;
}

.tabbing-box .tab-pane .accordion-item{
    background: transparent;
}

.tabbing-box .nav-tabs {
    border-bottom: none;
    background: #fff;
}

.tabbing-box .nav-tabs .nav-link {
    display: block;
    padding: 12px 17px 10px;
    font-weight: 500;
    color: #000000;
}

.img-tab {
    object-fit: none;
    object-position: center;
    width: 100%;
    max-height: 250px;
    margin-bottom: 1rem;
}
.nav-tabs{
    border-color: #F26623 !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
 {
    border-color: #F26623 !important;
    border-bottom: none !important;
    
}
.nav-tabs .nav-link:not(.active) {
    border-color: #F26623;
    border-top:none;
    border-left:none;
    border-right:none;
}

.tabbing-box .nav-tabs li {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black;
    border-radius: 0;
}


.tabbing-box .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
    border-radius: 0;
    color: #F26623;
}

.tabbing-box .nav-tabs .nav-link {
    border: none;
}

/** Section header start **/
.section-header {
    margin:32px 0 50px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-family: 'Prompt', sans-serif;
}

.section-header h2{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 40px;
    font-family: 'Prompt', sans-serif;
}

.section-header h1:after, 
.section-header h2:after, 
.section-header h3:after {
    position: absolute;
    content: attr(data-title);
    text-transform: uppercase;
    top: 10px;
    left: 50%;
    width: 100%;
    z-index: -1;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #6a6a6aa6;
    font-size: 70px;
    font-weight: 700;
    opacity: .2;
    line-height: 0;
    -webkit-user-select: none;
    /* -webkit-text-stroke: 1px #000; */
}

.section-header.sh-two h2{
    color: #fff;
}

.section-header.sh-two h1:after, .section-header.sh-two h2:after, .section-header.sh-two h3:after {
    color: #a1a1a1a6;
    opacity: 1;
}

/** Compare table start **/
.compare-table tr {
    border: 1px solid #ececec;
}

.compare-table tbody{
    border-top: 1px solid #ececec;
}

.compare-table tr:first-child {
    border-top: none;
}

.compare-table tr td:first-child {
    width: 350px;
    background: #f8f9fa;
    font-weight: 500;
}

.compare-table tr td {
    box-sizing: border-box;
    font-size: 15px;
    color: #6d6d6d;
    padding: 20px 15px;
    text-align: center;
    width: 395px;
    font-weight: 400;
}

.compare-table tr td .fa-times {
    color: red;
}

.compare-table tr td .fa-check {
    color: green;
}

.compare-table{
    margin-bottom: 20px;
}

/** Submit property start **/
.submit-property .checkbox {
    padding-left: 0;
    margin-bottom: 10px;
}

.submit-property .heading-2 {
    margin: 0 0 20px;
    font-size: 23px;
}

.submit-address form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 13px;
    border: 1px solid #e8e7e7;
    outline: none;
    color: #6c6c6c;
    height: 45px;
    border-radius: 2px;
}

.submit-address .search-fields{
    min-height: 45px;
    border-radius: 2px;
}

.submit-address form textarea {
    min-height: 160px;
}

.submit-address form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    color: #212121;
}

.pad-20 {
    padding: 25px;
}

.pad-2 {
    padding: 25px 25px 10px;
}

.submit-address form .form-group {
    margin-bottom: 20px;
}

.dropzone-design {
    border: 2px dashed #d0d0d0;
    min-height: 100px;
}

.dropzone-design, .dropzone-design * {
    box-sizing: border-box;
}

.dropzone-design {
    min-height: 160px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px;
}

.dropzone-design.dz-clickable {
    cursor: pointer;
}

.dropzone-design.dz-clickable * {
    cursor: default;
}

.dropzone-design.dz-clickable .dz-message, .dropzone-design.dz-clickable .dz-message * {
    cursor: pointer;
}

.dropzone-design.dz-started .dz-message {
    display: none;
}

.dropzone-design.dz-drag-hover {
    border-style: solid;
}

.dropzone-design.dz-drag-hover .dz-message {
    opacity: 0.5;
}

.dropzone-design .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone-design .dz-preview:hover {
    z-index: 1000;
}

.dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview.dz-file-preview .dz-image {
    border-radius: 3px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone-design .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview.dz-image-preview {
    background: white;
}

.dropzone-design .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.dropzone-design .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.dropzone-design .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone-design .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
}

.dropzone-design .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone-design .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone-design .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.dropzone-design .dz-preview .dz-details .dz-filename span, .dropzone-design .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.dropzone-design .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.dropzone-design .dz-preview .dz-image {
    border-radius: 3px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    transition: all 0.3s;
    display: block;
    z-index: 10;
}

.dropzone-design .dz-preview .dz-image img {
    display: block;
    filter: blur(0px);
    transition: all 0.4s;
}

.dropzone-design .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone-design .dz-preview .dz-success-mark, .dropzone-design .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone-design .dz-preview .dz-success-mark svg, .dropzone-design .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone-design .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.dropzone-design .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}

.dropzone-design .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}

.dropzone-design .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;
}

.dropzone-design .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -ms-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.dropzone-design .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone-design .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.dropzone-design .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white;
}

.dropzone-design .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
}

.dropzone-design {
    position: relative;
    border: 2px dashed #d0d0d0;
    border-radius: 3px;
    background: #fcfcfc;
    transition: all 0.3s linear;
    display: inline-block;
    width: 100%;
}

.dropzone-design:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
    background-color: #66676b;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s linear;
    border-radius: 3px;
}

.dropzone-design:hover:before {
    opacity: 0.4;
}

.dropzone-design .dz-message {
    text-align: center;
    margin: 50px 0;
    font-size: 17px;
    color: #888;
    transition: all 0.3s linear;
    position: relative;
    z-index: 11;
}

.dropzone-design:hover .dz-message {
    color: #66676b;
}

.dz-message i {
    display: block;
    font-size: 32px;
    margin-bottom: 15px;
}

.dropzone-design .dz-default.dz-message span {
    display: inline;
    line-height: 117px;
}

/**  Specifications start **/
.book-btn {
    display: block;
    height: 41px;
    margin-bottom: 15px;
    border-radius: 3px;
}

.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.page_loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url(../img/loader.gif) center no-repeat #fff;
}

.slider-section .simple-slider {
    margin-bottom: 30px;
}

/** Icons library **/
.icon-lists .heading {
    margin: 0 0 10px 0;
    text-transform: uppercase;
}

.glyph .bshadow0{
    background: #fff;
    text-align: center;
    padding: 20px 10px;
    font-size: 16px;
    color: #5a5959;
    margin-bottom: 20px;
}

.glyph .glyph-icon {
    font-size: 24px;
    font-weight: 500;
    color: #555;
}

.glyph fieldset,
.glyph .hidden-true {
    display: none;
}

.glyph .bshadow0 .lnr {
    font-size: 24px;
    color: #555;
    display: block;
    line-height: 35px;
}

.icon-lists mark {
    margin-bottom: 30px;
    display: inline-block;
    background: #fff;
    padding: 7px 14px;
}

.font-awesome-icons .col-print-4 {
    margin-bottom: 15px;
}

.font-awesome-icons {
    font-size: 14px;
    color: #555;
}

.font-awesome-icons .text-muted {
    display: none;
}

/** Portfolio item **/
.portfolio-item {
    margin-bottom: 30px;
}

.pg-4 .portfolio-item{
    margin-bottom: 0;
}

/** Option panel **/
.default-plate {
    background: #376bff
}

.blue-plate {
    background: #2048bb
}

.yellow-plate {
    background: #ffb400
}

.red-plate {
    background: #f0151f
}

.green-light-plate {
    background: #1abc9c
}

.green-plate {
    background: #7dba21
}

.yellow-light-plate {
    background: #faaa5d
}

.green-light-2-plate {
    background: #0dcdbd
}

.olive-plate {
    background: #b3c211
}

.purple-plate {
    background: #8e44ad
}

.midnight-blue-plate {
    background: #ff214f
}

.brown-plate {
    background: #A14C10
}

.setting-button {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 45px;
    height: 45px;
    line-height: 45px;
    left: -45px;
    color: #2b303b;
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    display: none;
}

/** Option Panel **/

/** Full page search **/
#full-page-search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    z-index: 99999;
    padding: 15px;
}

#full-page-search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

#full-page-search input[type="search"] {
    position: absolute;
    top: 50%;
    background: #fff;
    width: 400px;
    text-align: center;
    border: none;
    border-radius: 3px;
    margin: 0 auto;
    margin-top: -51px;
    padding: 12.5px 20px;
    outline: none;
    font-size: 17px;
}

#full-page-search .btn-sm {
    padding: 10px 30px 9px;
}

#full-page-search .full-page-search-inner{
    max-width: 400px;
    margin: 0 auto;
}

#full-page-search .btn {
    position: absolute;
    top: 50%;
    margin-top: 10px;
    margin-left: 137px;
}

#full-page-search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    opacity: 1;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 25px;
    outline: 0 auto -webkit-focus-ring-color;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    background: #3a3a3a;
}

/* Mtb **/
.mtb-50 {
    margin: 50px 0;
}

.mtb-30 {
    margin: 30px 0;
}

/* Mt **/
.mt-20{
    margin-top: 20px;
}

/* Mb **/
.mb-40 {
    margin-bottom: 40px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-25{
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10{
    margin-bottom: 10px;
}

/** Option Panel **/
.option-panel {
    z-index: 9999;
    width: 193px;
    position: fixed;
    right: 0;
    top: 20%;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 20px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.option-panel-collased {
    right: -193px;
}

.option-panel h2 {
    font-size: 14px;
    margin: 0 0 0 0;
    text-align: center;
    padding: 0 0 10px;
    font-weight: 500;
}

.option-panel .color-plate {
    width: 32px;
    height: 32px;
    float: left;
    margin: 3px;
    border-radius: 0;
    cursor: pointer;
}

/** Overview bgi **/
.overview-bgi {
    position: relative;
}

.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.dropdown:hover > .dropdown-menu {
    display: block;
    max-width: 700px;
    margin: auto;
}

.dropdown .dropdown-item:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

@media all and (min-width: 1200px) {
    .car-box-2 .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}

@media all and (min-width: 992px) {
    .banner img {
        /*animation: bgburnsEast 25s linear infinite alternate;
        -webkit-animation: bgburnsEast 25s linear infinite alternate;*/
    }

    .sticky-header .nav-link::after {
        display: none!important;
    }

    .sticky-header .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
        /* opacity: 0.9; */
    }

    .sh-2 .navbar-expand-lg .navbar-nav .nav-link{
        color: #F26623;
    }

    .sh-3 .navbar-expand-lg .navbar-nav .nav-link{
        color: #fff;
        opacity: 1;
    }

    .sticky-header .open-offcanvas {
        color: #fff;
        display: inline-block;
    }

    .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;;
    }

    .header-shrink .open-offcanvas {
        color: #515151;
    }

    .hide-carat .nav-link::after {
        display: none !important;
    }

    .sticky-header {
        position: fixed;
        top: 45px;
        z-index: 9999;
        width: 100%;
        background: rgba(12,12,12,0.1);
    }

    .sh-2{
        background: #fff;
        
    }

    .header-shrink {
        background: #fff;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
        top: 0px !important;
        z-index:1000;
    }

    .top-header-bg{
        background: rgba(12,12,12,0.1);
        border-bottom: 1px solid rgba(220, 220, 220, .2);
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
    }
}

/** Media **/
@media (max-width: 1200px) {
    .main-header .navbar-expand-lg .navbar-nav .nav-link {
        padding: 35px 10px;
    }

    .banner .carousel-control-next, .carousel-control-prev {
        width: 35px;
        height: 50px;
        margin: 5px;
    }

    .d-none-1200{
        display: none;
    }

    .banner-3 .banner-info-3 h1 {
        font-size: 40px;
    }

    .contact-section .informeson {
        margin: 0 auto 0 0;
    }

    .car-box-5 .w-100{
        width: auto!important;
    }

    .widget{
        padding: 30px 25px;
    }

    .car-box-2 .detail .custom-list{
        margin-bottom: 10px;
    }

    .banner .banner-slider-inner h1{
        font-size: 60px;
    }
    
    .service-car{
        padding-right: 15px;
    }

    .best-used-car h3 {
        font-size: 30px;
    }

    .main-header .navbar-expand-lg .user-account {
        padding: 30px 10px;
    }

    .main-header .form-inline .submit {
        margin-left: 10px;
    }

    .about-text {
        padding: 5px 0 0 30px;
    }

    .about-text h3 {
        font-size: 30px;
        margin:10px 0 15px;
    }

    .pages-404 .nobottomborder h1 {
        font-size: 34px;
    }

    .tabbing-box .nav-tabs .nav-link {
        padding: 10px 12px;
    }

    .sorting-options2 h5{
        line-height: normal;
    }


    .contact-1 .contact-form {
        padding: 50px;
    }

    .contact-1 .contact-info{
        padding: 50px;
    }
}

@media (max-width: 992px) {
    .content-area {
        padding: 30px 0 50px;
    }

    .content-area-2 {
        margin-bottom: 80px;
    }

    .content-area-3 {
        margin-bottom: 50px;
    }

    .content-area-4 {
        padding: 30px 0 50px;
    }

    .content-area-5 {
        padding: 80px 0;
    }

    .content-area-6 {
        padding: 80px 0 50px;
    }

    .content-area-7{
        padding-bottom: 60px;
    }

    .content-area-8 {
        padding: 80px 0 50px;
    }

    .content-area-9 {
        padding: 80px 0 70px;
    }

    .content-area-10{
        padding: 80px 0 40px;
    }

    .content-area-11{
        padding: 50px 0;
    }

    .content-area-12{
        padding: 80px 0 60px;
    }

    .content-area-13 {
        padding: 80px 0 60px;
    }

    .content-area-14 {
        margin-bottom: 70px;
    }

    .content-area-15 {
        padding: 80px 0 10px;
    }

    .content-area-16 {
        padding: 80px 0 70px;
    }

    .content-area-17{
        padding: 80px 0 80px;
    }

    .content-area-18{
        padding: 80px 0 80px;
    }

    .content-area-19{
        padding: 80px 0 30px;
    }

    .content-area-20 {
        padding-top: 80px;
    }

    .content-area-22{
        padding-top: 0;
    }

    .sub-banner .breadcrumb-area h1 {
        font-size: 45px;
    }

    .main-footer-2 .footer-menu ul li a:before {
        right: -10px;
    }

    .main-footer-2 .footer-menu ul li a {
        margin-right: 20px;
    }

    .main-footer-2 .footer-menu ul {
        margin-bottom: 30px;
    }

    .car-model-2 .modal-dialog {
        margin: 30px auto;
        padding: 0 15px;
    }

    .section-header {
        margin: 17px 0 40px;
    }

    .section-header h2 {
        font-size: 30px;
    }

    .section-header h1:after, .section-header h2:after, .section-header h3:after {
        font-size: 70px;
    }

    .content-area-21 {
        padding: 30px 0 70px;
    }

    .contact-section .bg-img {
        display: none;
    }

    .faq-body .image {
        display: none;
    }

    .contact-section .form-section{
        padding: 30px 15px;
    }

    .contact-1 .contact-innner {
        margin: 0 auto -80px;
    }

    .contact-1 .contact-form {
        border-right: none;
        border-bottom: solid 1px #e5e5e5;
    }

    .footer .subscribe-newsletter h3 {
        font-size: 20px;
        line-height: 50px;
    }

    .footer .subscribe-newsletter .form-control {
        width: 78%;
        padding: 10px 20px;
        margin-right: 2%;
        height: 50px;
    }

    .footer .subscribe-newsletter .btn {
        height: 50px;
        width: 20%;
    }

    .car-box-5 .carousel-caption {
        padding: 50px 20px;
    }

    .car-box-5 img{
        height: 450px;
    }

    .banner-2 .slide h2 {
        font-size: 30px;
    }

    .banner-content3 {
        margin-top: 0px;
    }

    .banner .carousel-control-next, .carousel-control-prev {
        width: 30px;
        height: 50px;
        border-radius: 2px;
        top: 45%;
        margin: 5px;
    }

    .counter-box-1{
        padding: 0;
        margin-bottom: 40px;
    }

    .counters-1 {
        padding: 80px 0 40px;
    }

    .counter-box-1 i {
        font-size: 45px;
    }

    .counter-box-1 h1 {
        font-size: 30px;
    }

    .pages-404 .pages-404-inner h1 {
        font-size: 150px;
    }

    .counter-box-1 h5 {
        font-size: 18px;
    }

    .bi5 {
        margin-top: 0px;
    }

    .banner-content2{
        -webkit-box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.2);
        box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.2);
        padding: 30px 20px;
    }

    .banner .bs{
        top: 35%;
    }

    .counter-box h1 {
        margin: 10px 0 15px;
        font-size: 30px;
    }

    .counter-box h5 {
        font-size: 16px;
    }

    .our-newslatters-2 .inner {
        text-align: center;
    }

    .our-newslatters-2 .inner p {
        margin-bottom: 20px;
        font-size: 16px;
    }

    .our-newslatters-2 .newsletter-content-wrap {
        max-width: 500px;
        margin: 0 auto;
    }

    .our-newslatters-2 .inner{
        padding: 30px 15px;
    }

    .sorting-options2 h5{
        line-height: 45px;
    }
    
    .car-model-2 .modal-right-content {
        padding: 30px 30px 0;
    }

    .car-model-2 .modal-left, .modal-right {
        flex: inherit;
    }

    .car-model-2 .modal-body {
        background: #fff;
        padding: 0 15px 30px;
    }

    .car-model-2 .modal-left-content .description {
        padding: 30px 30px 0;
    }

    .btn-white-lg-outline {
        padding: 9px 28px 8px 28px;
    }

    .banner .btn-lg {
        cursor: pointer;
        padding: 10px 28px 9px 28px;
    }

    .as-2{
        display: inline-block;
        width: 100%;
    }

    .about-car{
        padding: 80px 0;
    }

    .footer .social-list-2{
        float: none;
    }

    .about-slider-box img {
        left: 0;
        top: 0;
    }

    .about-slider-box:before{
        display: none;
    }

    .service-car{
        padding-right: 0;
        margin-bottom: 30px;
    }

    .best-used-car h3 {
        font-size: 30px;
    }

    .faq-info .nav-tabs .nav-link {
        padding: 10px 20px 11px;
        border-radius: 3px;
        font-size: 15px;
    }

    .faq-info{
        margin-bottom: 30px;
    }

    .faq-body .faq-info{
        margin-bottom: 0;
    }

    .compare-info .faq-info{
        margin-bottom: 0;
    }

    .pages-404 h1 {
        font-size: 50px;
    }

    .blog-2{
        margin: 25px 0 30px;
    }

    .blog-2 h3 a {
        font-size: 22px;
    }

    .main-title-2 p {
        line-height: 26px;
        font-size: 16px;
    }

    .mt-50 {
        margin-top: 0;
    }

    .service-section-2 {
        padding: 80px 0 50px;
    }

    .banner h5 {
        font-size: 22px;
        line-height: 30px;
    }

    .testimonial-2 .main-title{
        margin-bottom: 40px;
        text-align: center;
    }

    .sb-2{
        display: flex;
    }

    .details-banner .cover-buttons ul {
        float: left;
    }

    .slide-2{
        margin-bottom: 40px;
    }

    .details-banner .cover-buttons {
        margin-top: 20px;
    }

    .details-banner {
        height: 500px;
    }

    .d-none-992{
        display: none;
    }

    .none-btn-992{
        display: none;
    }

    .counter-box-3 .icon {
        font-size: 37px;
        line-height: 80px;
        width: 80px;
        height: 80px;
    }

    .counter-box-3 h1 {
        font-size: 32px;
    }

    .counter-box-3 h5 {
        font-size: 17px;
    }

    .counter-box-3 {
        padding: 0;
        margin-bottom: 40px;
    }

    .counters-3 .border-l {
        border-left: none;
    }

    .counters-3 .border-r {
        border-right: none;
    }

    .counters-3 {
        padding: 80px 0 40px;
    }

    /* .team-2 .detail {
        padding: 30px;
    } */

    .car-model-2 h4 {
       font-size: 20px;
    }

    .service-section-2 .main-title{
        text-align: center;
        margin-bottom: 40px;
    }

    .sb-5{
        display: none;
    }

    .sb-6{
        display: flex;
    }

    .search-box-4 {
        max-width: 260px!important;
        top: 15%;
        right: 12%;
    }

    .search-box-3 .form-group {
        margin-bottom: 15px;
    }

    .search-box-3 .range-slider {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .search-box-3{
        padding: 30px 0 15px;
    }

    .heading-4{
        margin-bottom: 25px;
    }

    .cart-total-box{
        margin-top: 30px;
    }

    .typography .heading-3{
        margin-bottom: 15px;
    }

    .typography .mb-50 {
        margin-bottom: 40px;
    }

    .search-compare h3 {
        font-size: 21px;
    }

    .vs {
        margin: 0 auto 30px;
    }

    .main-header .dropdown-menu {
        border-top: solid 1px #f1f1f1;
        border-bottom: solid 1px #f1f1f1;
    }

     .logo {
         margin: 15px 0;
    }

    .logo img{
        height: 40px;
    }

    .p-box-2{
        margin-bottom: 45px;
    }

    .banner .banner-slider-inner h1 {
        font-size: 42px;
    }

    .search-box-2{
        padding: 30px 20px 15px;
    }

    .banner .carousel-content.banner-info {
        /*top: 27%;*/
    }

    .inline-search-area .btn-search{
        height: 45px;
        line-height: 45px;
    }

    .inline-search-area .search-col .form-control, .inline-search-area button.btn-search {
        height: 45px;
        margin-bottom: 0;
        border-radius: 3px!important;
        padding: 0;
        width: 100%;
        font-size: 14px;
    }

    .banner-slider-inner .show-more-options {
        display: none;
    }

    .banner-slider-inner .row-4{
        margin: 0;
    }

    .inline-search-area .search-col {
        padding: 0 7.5px;
        margin-bottom: 15px;
    }

    .inline-search-area .bootstrap-select button {
        padding: 11.5px 15px 11.5px 15px!important;
        border-radius: 3px;
    }

    .inline-search-area .search-fields {
        min-height: 40px;
    }

    .inline-search-area .dropdown-toggle::after {
        top: 24px;
    }

    .sb-6 .dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }

    .car-box-2 .detail{
        padding: 25px 35px 20px 25px;
    }

    .main-header .navbar-expand-lg .navbar-nav .link-color {
        padding: 10px 15px!important;
        border-radius: 0;
        border: none;
        font-size: 14px;
        background: #f9f9f9!important;
        border: transparent !important;
        margin: 0;
        color: #515151!important;
    }

    .header-ml {
        margin-left: 0;
    }

    #search-style-3 {
        padding: 30px 0 80px;
    }

    .helping-sentar {
        padding-top: 80px;
    }

    .tab-box-2 .nav-link {
        display: block;
        padding: .5rem .3rem;
    }

    .ui-item{
        margin-bottom: 30px;
    }

    .alert-2{
        margin-bottom: 30px;
    }

    .header-2{
        padding: 0;
    }

    .main-title {
        margin-bottom: 40px;
    }

    .main-title-2 {
        margin-bottom: 40px;
    }

    .search-area {
        padding: 25px 20px 5px;
        border-radius: 0;
    }

    .search-options-btn, .search-options-btn:hover {
        padding: 7px 10px 8px 10px;
        border-radius: 3px;
    }

    .search-options-btn, .search-options-btn i {
        margin-left: 0;
    }

    .search-area .form-group{
        margin-bottom: 15px;
    }

    .about-slider-box {
        margin-bottom: 20px;
    }

    .search-area-2 .range-slider {
        margin-top: 15px;
        margin-bottom: 1rem;
    }

    .bg-grea .range-slider {
        margin-top: 15px;
        margin-bottom: 1rem;
    }

    .search-area-2{
        padding: 30px 0 10px;
    }

    .t-c{
        text-align: center;
    }

    .main-title h1 {
        font-size: 30px;
    }

    .main-title-2 h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .hidden-mb-20{
        margin-bottom: 20px;
    }

    .main-header .search-fields button {
        background: #efefef;
    }

    .main-header .inline-search-small .inline-search-area .search-col:first-child .form-control {
        border-radius: 50px 0 0 50px !important;
        background-color: #efefef;
    }

    .search-compare h4{
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    .header-transparent {
        position: static;
        z-index: 9;
        width: 100%;
    }

    .banner .banner-slider-inner h2 {
        font-size: 42px;
    }

    .banner .banner-slider-inner {
        top: 0%;
    }

    .banner .banner-opt .banner-slider-inner h1 {
        font-size: 20px;
    }

    .banner .banner-opt .banner-slider-inner p {
        line-height: 22px;
        font-size: 14px;
        max-width: 90%;
        margin: 0px auto;
        margin-bottom: 5px;
    }

    .pages-404 .nobottomborder h1 {
        font-size: 30px;
    }

    .pb-hediin-60 {
        padding-bottom: 60px;
    }

    .service-info.h-bdr {
        border: solid 1px #efefef;
    }

    .main-header .navbar-expand-lg .navbar-nav .nav-link {
        padding: 10px 15px;
        background: white;
    }

    .main-header .navbar-expand-lg .navbar-nav .link-color:hover {
        background: #f9f9f9;
    }

    .main-header .dropdown-menu a {
        padding: 10px 30px;
    }

    .main-header .navbar-expand-lg .user-account {
        padding: 15px 10px;
    }

    .main-header .navbar-expand-lg .user-account li {
        font-size: 12px;
    }

    .main-header .form-inline .submit {
        margin: 0 0 50px 10px;
        font-size: 12px;
    }

    .navbar-light .navbar-toggler {
        border: none;
        font-size: 18px;
        font-weight: 400;
    }

    .main-header .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-light .navbar-toggler span{
        color: #fff;
    }

    .navbar-toggler {
        padding: 0 0.3rem;
        width: auto;
        height: 32px;
        outline: none;
        border-radius: 0;
    }

    .contect-agent-photo img {
        margin-top: 40px;
    }

    .about-text {
        padding: 0;
    }
    .inline-search-area .bootstrap-select button {
        border-bottom: none;
    }

    .banner h3 {
        font-size: 42px;
    }

    .b p{
        margin-bottom: 0;
    }

    .main-title p {
        line-height:26px;
        font-size: 16px;
    }

    .about-text h3{
        font-size: 30px;
    }

    .search-box-4{
        display: none;
    }

    .text-l {
        text-align: center!important;
    }

    .text-r {
        text-align: center!important;
    }

    .sub-banner {
        height: auto;
    }

    .sticky-header {
        position: fixed;
        z-index: 9999;
        width: 100%;
    }

    .sh-2{
        background: #fff;
    }

    .top-header-bg {
        background: rgba(12, 12, 12, 0.18);
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
        border-bottom: 1px solid rgba(220, 220, 220, .2)
    }

    .sticky-header {
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 44px;
    }

    .sb-6{
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1)!important;
        background: #fff;
    }

    .footer .footer-gallery {
        margin-left: 0;
    }

    .footer .footer-inner {
        padding: 60px 0 0;
    }
}

@media (max-width: 768px) {
    .sub-banner .breadcrumbs li a {
        font-size:10px;
    }
    .sub-banner .breadcrumb-area{
        position:unset
    }

    .sub-banner {
        height: auto !important;
    }

    .intro-text h3 {
        line-height: inherit;
    }

    .filteriz-navigation li {
        padding: 5px 22px 4px;
        font-size: 16px;
    }

    .footer-gallery{
        max-width: 100%;
    }

    .banner-3 .banner-info-3 h1 {
        font-size: 34px;
    }

    .about-text h3{
        font-size: 27px;
    }

    .content-area-21 {
        padding: 30px 0 50px;
    }

    .section-header {
        margin: 17px 0 35px;
    }

    .best-used-car h3 {
        font-size: 27px;
    }

    .section-header h1:after, .section-header h2:after, .section-header h3:after {
        font-size: 60px;
        top: 10px;
    }

    .social-media .icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .content-area-20 {
        padding-top: 60px;
    }

    .content-area-22{
        padding-top: 0;
    }

    .contact-1 .contact-innner {
        margin: 0 auto -60px;
    }

    .contact-1 .contact-form {
        padding: 30px;
    }

    .contact-1 .contact-info {
        padding: 30px;
    }

    .sub-footer p {
        margin-bottom: 20px;
        text-align: center;
        line-height: normal;
    }

    .footer .social-media {
        float: inherit;
    }

    .testimonial-3 .testimonial-item-new::before {
        clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%);
    }

    .car-box-2 .detail .custom-list{
        margin-bottom: 15px;
    }

    .banner .btn-3 {
        padding: 0 42px 0 20px;
        line-height: 41px;
        letter-spacing: 0.5px;
        font-size: 13px;
    }

    .pages-404 .pages-404-inner h1 {
        font-size: 100px;
    }

    .pages-404 .pages-404-inner h5 {
        font-size: 20px;
    }

    .banner .btn-4 {
        padding: 0 42px 0 20px;
        line-height: 41px;
        letter-spacing: 0.5px;
        font-size: 13px;
    }

    .banner .btn-5 {
        padding: 14px 25px 12px;
        font-size: 13px;
        letter-spacing: 0.5px;
    }

    .banner-3 .btn-4 {
        padding: 0 20px 0;
    }

    .service-section-2 {
        padding: 60px 0 30px;
    }

    .service-section-2 .main-title{
        margin-bottom: 30px;
    }

    .testimonial-2 .main-title{
        margin-bottom: 30px;
    }

    .inline-search-area .search-col {
        margin-bottom: 10px;
    }

    .main-header .company-logo img {
        height: 40px;
    }

    .main-header .company-logo-2 img {
        height: 40px;
    }

    .banner .price{
        height: 90px;
    }

    .banner .carousel-content.bi-2{
        padding-top: 75px;
    }

    .shop-table.cart td, .shop-table.cart th {
        padding-left: 0;
    }

    .shop-table.cart .product-thumbnail img {
        width: 70px;
    }

    .shop-table.cart .product-thumbnail, .shop-table.cart .product-name a {
        font-size: 13px;
    }

    .shop-table.cart .qty {
        width: 40px;
    }

    .cart-total-box {
        padding: 20px;
    }

    .car-box-2 .detail{
        padding: 25px 30px 20px 30px;
    }

    .car-box-2 .detail .title {
        font-size: 22px;
    }

    .vs {
        margin: 0 auto 30px;
    }

    .main-title-2 h1 {
        font-size: 27px;
    }

    .content-area-17{
        padding: 60px 0 60px;
    }

    .content-area-18{
        padding: 60px 0 60px;
    }

    .content-area-19{
        padding: 60px 0 10px;
    }

    .main-title h1 {
        font-size: 27px;
    }

    .search-area-3 {
        padding: 25px 0 10px;
        background: #efefef;
    }

    .none-768{
        display: none;
    }

    .footer .subscribe-newsletter h3 {
        line-height: normal;
        margin-bottom: 15px;
    }

    #full-page-search {
        width: 100%;
    }

    #full-page-search input[type="search"] {
        width: 300px;
    }

    #full-page-search .full-page-search-inner {
        max-width: 300px;
    }

    #full-page-search .btn {
        margin-left: 87px;
    }

    .option-bar .sorting-options-3.float-end{
        float: left!important;
    }

    .banner-3 .banner-info-3 p{
        color: #fff;
    }

    .banner-3 .left-slide {
        display: none;
    }

    .banner-3 .slider-container .action-buttons button {
        position: absolute;
        left: 5px;
        margin: 2px 0;
    }

    .banner-3 .right-slide {
        left: 0%;
        width: 100%;
    }

    .banner-3 .slider-container .action-buttons .up-button {
        margin-top: 5px;
        transform: translateX(0%);
    }

    .banner-3 .banner-info-3.b2i-2{
        display: initial;
        position: absolute;
        top: 40%;
        max-width: 100%!important;
    }

    .banner-3 .banner-info-3 {
        padding: 0 50px;
    }

    .widget {
        padding: 30px 20px;
    }

    .heading-car h3 {
        font-size: 24px;
    }

    .heading-car .price-box-3 {
        font-size: 40px;
    }

    .counters-3{
        padding: 60px 0 20px;
    }

    .counters-1 {
        padding: 60px 0 20px;
    }

    .testimonial-3 .testimonial-item-new {
        padding: 30px;
    }

    .testimonial-3 .testimonial-item-new p {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .testimonial-3 .testimonial-item-new .author-content h5 {
        font-size: 16px;
    }

    .testimonial-3 .testimonial-item-new .pl-40 {
        padding-left: 30px;
    }

    .testimonial-3 .testimonial-item-new .left-line::before {
        width: 20px;
    }

    .testimonial-3 .testimonial-item-new .author-img .author-avatar {
        float: left;
    }

    .testimonial-3 .testimonial-item-new .author-img .author-avatar img{
        height: 80px;
    }

    .testimonial-3 .testimonial-item-new .author-img .icon{
        top: 35px;
        right: -10px;
    }

    .sub-banner .breadcrumbs li {
        font-size: 10px;
    }

    .sticky-header {
        top: 0;
    }

    .counters-2 {
        padding: 60px 0 30px;
    }

    .counter-box-2 {
        position: relative;
        max-width: 300px;
        margin: 0 auto 30px;
        padding: 15px;
        border: solid 1px rgba(255,255,255,0.2);
     }
    
    .sorting-options-3.float-right{
        float: left!important;
    }

    .sorting-options2 h5 {
        line-height: normal;
        margin-bottom: 15px;
    }

    .alert {
        text-transform: uppercase;
        font-size: 12px;
        border-radius: 0;
        padding: 12.5px 20px;
    }

    .pages-404 h1 {
        font-size: 40px;
    }

    .search-area-3 .inline-search-area .bootstrap-select button {
        padding: 5px 15px 5px 15px;
        border-right: none!important;
    }

    .search-area-3 .inline-search-area .dropdown-toggle::after {
        top: 20px;
    }

    .search-area-3 .search-fields {
        min-height: 40px;
    }

    .search-area-3 .inline-search-area .search-col .form-control, .inline-search-area button.btn-search {
        margin-bottom: 0;
        padding: 0;
        font-size: 14px;
    }

    .elements-page .mb-50{
        margin-bottom: 40px;
    }

    .top-header{
        display: none;
    }

    .banner .banner-slider-inner h1 {
        font-size: 34px;
    }

    .banner .banner-info ul {
        margin-top: 10px;
    }

    .banner .carousel-content.banner-info {
        /*top: 33%;*/
        text-align: center;
    }

    .search-box-2{
        padding: 30px 0 20px;
    }

    .heading-car-2 h3 {
        font-size: 24px;
    }

    .banner .banner-slider-inner p{
        font-size: 16px;
        margin-bottom: 20px;
    }

    .search-area-3{
        display: none;
    }

    .heading-car-2 {
        padding: 15px;
        top: 15px;
        left: 15px;
    }

    .car-properties .list-inline-item {
        width: 45px;
        margin: 3px!important;
    }

    .tabbing-box .nav-tabs .nav-link {
        padding: 8px 14px;
        font-size: 15px;
    }

    .tabbing-box .nav-tabs {
        background: transparent;
        border-bottom: transparent;
    }

    .cars{
        padding: 0;
    }

    .photo-gallery .col-pad {
        padding: 0 15px 0;
    }

    .intro-section .btn {
        float: none;
    }

    .intro-section {
        text-align: center;
    }

    .intro-text h3 {
        margin-bottom: 15px;
    }

    .intro-section {
        padding: 25px 0 30px;
    }

    #search-style-3 {
        padding: 30px 0 60px;
    }

    .compare-table tr td {
        box-sizing: border-box;
        padding: 10px 7px;
    }

    .dashboard-content {
        padding: 30px 15px 0;
        overflow: hidden;
    }

    .car-box-2 .price-box{
        right: 30px;
    }

    .dashboard-header .breadcrumb-nav {
        right: 15px;
        display: inline-block;
        font-size: 15px;
        float: left;
    }

    .dashboard-header .breadcrumb-nav ul li {
        display: inline-block;
        list-style: none;
        margin: 0 15px 0 0;
    }

    .edit-profile-photo{
        width: 160px;
        margin-bottom: 80px;
    }

    .search-options-btn, .search-options-btn:hover {
        padding: 5px 8px 6px 8px;
        border-radius: 3px;
        font-size: 13px;
    }

    .main-title {
        margin-bottom: 30px;
    }

    .main-title-2 {
        margin-bottom: 30px;
    }

    .main-header {
        box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
    }

    .search-area-2 .form-group {
        margin-bottom: 10px;
    }

    .property-meta{
        padding: 20px 5px;
    }

    .search-area-2 {
        padding: 30px 20px 10px;
    }

    .coming-soon-info .form-control {
        width: 65%;
    }

    .coming-soon-info .btn, .coming-soon-info .btn:hover, .coming-soon-info .btn:active {
        width: 35%;
    }

    .property-meta ul li{
        font-size: 14px;
    }

    .property-meta ul li i {
        margin-right: 7px;
        font-size: 17px;
    }

    .car-box-2 .footer {
        padding: 15px 35px 15px 35px;
    }

    .car-box-2 .hdg {
        padding: 20px 35px;
    }

    .sub-footer .social-list {
        float: none;
    }

    .sub-footer {
        text-align: center;
    }

    .coming-soon-counter div {
        font-size: 35px;
        width: 100px;
        margin: 0 3px 10px;
    }

    .coming-soon-counter div span {
        font-size: 15px;
    }

    .coming-soon h1 {
        font-size: 34px;
    }

    .banner .banner-slider-inner h2 {
        font-size: 34px;
        margin-bottom: 20px;
    }

    .banner-car-box .meta ul li {
        margin-right: 15px;
    }

    .banner-car-box  .mb-30 {
        margin-bottom: 20px;
    }

    .banner h3 {
        font-size: 34px;
    }

    .banner .btn-md {
        padding: 9px 15px;
        font-size: 12px;
    }

    .blog-social-list {
        float: left;
        margin-top: 10px;
    }

    .countdown .top {
        font-size: 30px;
    }

    .pages-404 .nobottomborder h4 {
        font-size: 16px;
    }

    .pages-404 .nobottomborder {
        margin-bottom: 0;
    }

    .setting-button {
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        left: -40px;
    }

    .inside-car iframe {
        height: 220px!important;
    }

    .breadcrumbs {
        font-size: 12px;
    }

    .back-to-listings {
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 15px;
    }

    .search-area label {
        display: none;
    }

    .listings-parallax h1 {
        font-size: 25px;
        margin: 0 0 20px;
    }

    .listings-parallax{
        text-align: center;
        padding-top: 60px;
    }

    .contact-details .media .media-left {
        padding-right: 20px;
    }

    .search-area-inner {
        margin: 0 -15px;
    }

    .search-box-3 .search-area-inner {
        margin: 0;
    }

    .search-contents .col-xs-12,
    .search-contents .col-xs-6 {
        padding: 3px 10px;
    }

    .search-box .form-group {
        margin-bottom: 0;
    }

    .blog-box .detail .tags-box {
        margin-bottom: 20px;
    }

    .blog-box .detail .blog-share {
        float: left;
        text-align: left;
    }

    .comments ul {
        margin: 0 0 0 0;
    }

    .comment-body {
        margin: 10px 0 0 0;
        line-height: 20px;
        padding: 10px 0 0 0;
        font-size: 13px;
        text-align: justify;
    }

    .blog-banner {
        text-align: center;
        padding: 40px 0;
    }

    .comment p {
        line-height: 26px;
    }

    .sec-title-three h2 {
        margin-top: 0;
    }

    .content-area {
        padding: 30px 0 30px;
    }

    .content-area-2 {
        margin-bottom: 60px;
    }

    .content-area-3 {
        margin-bottom: 30px;
    }

    .content-area-4 {
        padding: 30px 0 30px;
    }

    .content-area-5 {
        padding: 60px 0;
    }

    .content-area-6 {
        padding: 60px 0 30px;
    }

    .content-area-7{
        padding-bottom: 40px;
    }

    .content-area-8 {
        padding: 60px 0 30px;
    }

    .content-area-10{
        padding: 60px 0 20px;
    }

    .content-area-11 {
        padding: 30px 0 20px;
    }

    .content-area-12{
        padding: 60px 0 40px;
    }

    .content-area-13 {
        padding: 60px 0 40px;
    }

    .content-area-14 {
        margin-bottom: 50px;
    }

    .content-area-15 {
        padding: 60px 0 10px;
    }

    .content-area-16{
        padding: 60px 0 50px;
    }

    .content-area-9{
        padding: 60px 0 50px;
    }

    .about-car{
        padding: 60px 0;
    }

    .about-text {
        padding: 0;
    }

    .search-area .form-group {
        margin-bottom: 10px;
    }

    .recent-posts-box .detail .location a {
        font-size: 14px;
    }

    .about-box {
        max-width: 100%;
    }

    .car-amenities ul li {
        list-style: none;
        margin-bottom: 10px;
    }

    .heading-car h5{
        font-size: 13px;
    }

    .typography-page p {
        font-size: 13px;
        line-height: 25px;
        text-align: justify;
    }

    .breadcrumb-area .btn-md {
        padding: 10px 20px;
    }

    .helping-sentar {
        padding-top: 60px;
    }

    .sub-banner .breadcrumb-area h1 {
        font-size: 35px;
    }

    .intro-section .btn-md {
        padding: 13px 27px 8px;
        height: 45px;
    }

}

@media (max-width: 580px) {
    .comment-content h6 span{
        display: none;
    }

    .banner .prl-30 {
        padding: 0 40px;
    }

    .heading-car .pull-left{
        display: inline-block;
        width: 100%;
        margin-bottom: 12px;
    }

    .heading-car .pull-right{
        float: left;
    }

    .heading-car .price-box-3 {
        font-size: 20px;
    }

    .heading-car .price-box-3 sup {
        top: -9px;
    }

    .coming-soon-counter div {
        font-size: 25px;
        width: 70px;
        padding: 10px 2px 10px;
    }

    .product-price{
        display: none;
    }

    .d_none{
        display: none;
    }

    /*.none-580{
        display: none;
    }*/

    .banner-car-box .meta ul li i {
        margin-right: 5px;
    }

    .search-options-btn, .search-options-btn:hover {
        left: 30px;
        bottom: 0px;
    }

    .setting-button {
        display: none;
    }

    .banner .slider-mover-left{
        display: none;
    }

    .banner .slider-mover-right{
        display: none;
    }
}

@media (min-width: 180px){
    .header-shrink {
        background: #fff!important;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
        top: 0px !important;
    }
}


.bg-fb{
    background:#3b5998 !important;
}
.bg-line{
    background: #d6249f !important;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%) !important;
}
.bg-insta{
    background:#00B900 !important;
}
.mfp-title p {
    color: white !important;
}
.text2line {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text1line {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-blue{
color:#00369A !important;
}
.bg-black{
background-color:black !important;
}
.text-red {
color:#E5121F !important;
}

.text-white {
color:#ffffff !important;
}

.bg-red {
background-color:#E5121F !important;
}

.section-black h2:after {
color: #F2F2F2 !important; 
}

.service h2:after {
color:#ffffff !important;
}

.footer{
background:unset;
}

.service-section-3{
background:unset;
}

.page-item.active .page-link{
background-color: #F26623;
border-color: #F26623;
}

.calendar-article ,.date-pub{
font-size:0.875rem !important;
}

.contact-add p {
padding-left:20px;
}

.bg-blue {
/* Created with https://www.css-gradient.com */
background: #0E0C52;
background: -webkit-linear-gradient(top left, #0E0C52, #102B3F);
background: -moz-linear-gradient(top left, #0E0C52, #102B3F);
background: linear-gradient(to bottom right, #0E0C52, #102B3F);
}

.btn-blue {
background-image: linear-gradient(to left, #1a4c98, #1151ab, #0655be, #0159d0, #055de3);
padding: 10px;
text-align: center;
text-transform: uppercase;
transition: 0.5s;
background-size: 200% auto;
color: white !important;          


display: block;

border-radius:8px;
}

.btn-grad {background-image: linear-gradient(to right, #D31027 0%, #EA384D 0%, #D31027 25%);}
.btn-grad {

padding: 10px;
text-align: center;
text-transform: uppercase;
transition: 0.5s;
background-size: 200% auto;
color: white !important;          
border-radius:3px;

display: block;
}

.sub-banner .breadcrumb-area{
top:60%;
}

.dropdown-toggle::after{
border-top:0em;
}
@media (max-width: 479px) {
.large-viewport {
display: none !important;
}
}

.small-viewport {
display: none !important;
}

@media (max-width: 479px) {
.small-viewport {
display: block !important;
}
}

.isShadow {
transform: translateY(-5px);
box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}
@media only screen and (max-width : 480px) {
#recaptcha_challenge_image{
margin: 0 !important;
width: 100% !important;
height: auto !important;
}
#recaptcha_response_field
{
margin: 0 !important;
width: 100% !important;
height: auto !important;
}
.recaptchatable #recaptcha_image {
margin: 0 !important;
width: 100% !important;
height: auto !important;
}
.recaptchatable .recaptcha_r1_c1, 
.recaptchatable .recaptcha_r3_c1, 
.recaptchatable .recaptcha_r3_c2, 
.recaptchatable .recaptcha_r7_c1, 
.recaptchatable .recaptcha_r8_c1, 
.recaptchatable .recaptcha_r3_c3, 
.recaptchatable .recaptcha_r2_c1, 
.recaptchatable .recaptcha_r4_c1, 
.recaptchatable .recaptcha_r4_c2, 
.recaptchatable .recaptcha_r4_c4, 
.recaptchatable .recaptcha_image_cell {

margin: 0 !important;
width: 100% !important;
background: none !important;
height: auto !important;
}

}

.breadcrumb-item + .breadcrumb-item:before {
font-family: 'Font Awesome\ 5 Free';
content: "\f105";
font-weight: 900;
}
.team-3 .team-social .team-overlay, .team-sub-line:before {
background: rgb(0 0 0 / 36%) !important;

}
.team-3:hover .team-social .team-overlay {
opacity:1 !important;
}
.team-3 .team-social a{
background:unset !important;
}

.team-3 .team-social a {
display: block;
font-size: 16px;
line-height: 1;
margin: 5px 0;
padding: 0px 0px 11px 0px !important;
background: #fff;
border-radius: 5%;
color: #000;
}
.social-block span {
font-size: 14px !important;}

.social-block  {
padding:2px;
}@media (max-width: 575.98px) {
.modal-fullscreen {
padding: 0 !important;
}
.modal-fullscreen .modal-dialog {
width: 100%;
max-width: none;
height: 100%;
margin: 0;
}
.modal-fullscreen .modal-content {
height: 100%;
border: 0;
border-radius: 0;
}
.modal-fullscreen .modal-body {
overflow-y: auto;
}
}
@media (max-width: 767.98px) {
.modal-fullscreen-sm {
padding: 0 !important;
}
.modal-fullscreen-sm .modal-dialog {
width: 100%;
max-width: none;
height: 100%;
margin: 0;
}
.modal-fullscreen-sm .modal-content {
height: 100%;
border: 0;
border-radius: 0;
}
.modal-fullscreen-sm .modal-body {
overflow-y: auto;
}
}
@media (max-width: 991.98px) {
.modal-fullscreen-md {
padding: 0 !important;
}
.modal-fullscreen-md .modal-dialog {
width: 100%;
max-width: none;
height: 100%;
margin: 0;
}
.modal-fullscreen-md .modal-content {
height: 100%;
border: 0;
border-radius: 0;
}
.modal-fullscreen-md .modal-body {
overflow-y: auto;
}
}
@media (max-width: 1199.98px) {
.modal-fullscreen-lg {
padding: 0 !important;
}
.modal-fullscreen-lg .modal-dialog {
width: 100%;
max-width: none;
height: 100%;
margin: 0;
}
.modal-fullscreen-lg .modal-content {
height: 100%;
border: 0;
border-radius: 0;
}
.modal-fullscreen-lg .modal-body {
overflow-y: auto;
}
}
.modal-fullscreen-xl {
padding: 0 !important;
}
.modal-fullscreen-xl .modal-dialog {
width: 100%;
max-width: none;
height: 100%;
margin: 0;
}
.modal-fullscreen-xl .modal-content {
height: 100%;
border: 0;
border-radius: 0;
}
.modal-fullscreen-xl .modal-body {
overflow-y: auto;
}
#myModal p, #myModal strong {
color:unset !important;
}
.social-pad {
margin-right:10px;
margin-top: 3px;
}
.social-block-2{
font-size:12px;
}

#myTabMenuTop .nav-link.active {
color: white;
}
.main-header .dropdown-menu #myTabContentTop a{
padding:unset;
font-size:unset;
}
.car-box-3 .detail .title{
font-size:1.2rem;
min-height:3.5rem;
}
.car-box-3 .detail p{
text-align:right;
}

.menuheader:hover {
color:white !important;
}


.custom-select
 {
    display: unset;
    width: unset;
    margin-top: 20px;
}





ul.navbar-nav li.nav-item  a.nav-link  {
    color:#F26623;
}



.btn-primary {
    color: #fff;
    background-color: #F26623;
    border-color: #F26623;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #F26623;
    border-color: #F26623;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem #F26623;
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #F26623;
    border-color: #F26623;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #F26623;
    border-color: #F26623;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #F26623;
  }
  














